import { Application, ApplicationFilters, EApplicationStatus } from '@/model/application';
import { Invoice, InvoiceStatus } from '@/model/invoice';
import {
    ContractModality,
    Job,
    JobFilters,
    JobStatus,
    MissionDurationType,
    RequiredExperience,
} from '@/model/job';
import { Salon, SalonCharacteristics, SalonFilters, SalonSize, SalonType } from '@/model/salon';
import {
    EmployeesFilters,
    GeographicAvailability,
    Resume,
    SkillType,
    SpokenLanguageLevel,
    User,
    UserFilters,
} from '@/model/user';
import Utils from '@/utils/utils';

export type Entity = User | Salon | Job | Application | Invoice | Resume | undefined;

export type MediaType = 'PROFILE' | 'PORTFOLIO' | 'VIDEO';

export type SearchFilter = UserFilters | SalonFilters | JobFilters | ApplicationFilters;

export type Suggestions =
    | 'formations'
    | 'technicalSkills'
    | 'relationalSkills'
    | 'personnalSkills'
    | 'brands';

export type Enum =
    | typeof Contract
    | typeof ContractModality
    | typeof EApplicationStatus
    | typeof GeographicAvailability
    | typeof HairdresserLevel
    | typeof InvoiceStatus
    | typeof JobStatus
    | typeof MissionDurationType
    | typeof RequiredExperience
    | typeof SalonCharacteristics
    | typeof SalonSize
    | typeof SalonType
    | typeof SkillType
    | typeof SpokenLanguageLevel;

export type SortableFilterState = UserFilters | SalonFilters | JobFilters | EmployeesFilters;

export interface PageableResponse<Entity> {
    content: Array<Entity>;
    pageable: PageSettings;
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: Sort;
    numberOfElements: number;
    first: boolean;
    empty: boolean;
}

export interface Sort {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}

export interface PageSettings {
    sort?: Sort;
    offset?: number;
    pageNumber: number;
    pageSize: number;
    unpaged?: boolean;
    paged?: boolean;
}

export interface Address {
    street?: string;
    city?: string;
    zipCode?: string;
    country?: string;
    location?: Position;
}

export interface Position {
    x: number;
    y: number;
}

export const Contract = {
    CDD: Utils.getLocalizedMessages().message.fixedTermContract,
    CDI: Utils.getLocalizedMessages().message.permanentContract,
    APPRENTICESHIP_CONTRACT: Utils.getLocalizedMessages().message.apprenticeshipContract,
};

export const HairdresserLevel = {
    APPRENTICE: Utils.getLocalizedMessages().message.apprentice,
    BEGINNER: Utils.getLocalizedMessages().message.beginner,
    EXPERIENCED: Utils.getLocalizedMessages().message.experienced,
    HAIRDRESSER_MANAGER: Utils.getLocalizedMessages().message.hairdresserManager,
    BARBER: Utils.getLocalizedMessages().message.barber,
    TRAINER: Utils.getLocalizedMessages().message.trainer,
};

export type HairdresserlevelStrings =
    | 'APPRENTICE'
    | 'BEGINNER'
    | 'EXPERIENCED'
    | 'HAIRDRESSER_MANAGER'
    | 'BARBER'
    | 'TRAINER'
    | 'TECHNICIAN'
    | 'COMMERCIAL';

/**
 * Get defualt adress object
 * @returns address
 */
export function defaultAddress(): Address {
    return {};
}
