import { apiSettings, checkErrorStatus, checkResponseStatus } from '@/api/global';
import { axiosAPI } from '@/axiosInstance';
import { Product, ProductTypeString, ResumeStock } from '@/model/product';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { AxiosError } from 'axios';

/**
 * Get product list
 *
 * @param {ProductTypeString} productType - The product type
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Product>>} - Product list
 */
async function getProducts(
    productType: ProductTypeString,
    message: MessageApiInjection
): Promise<Array<Product>> {
    try {
        const response = await axiosAPI.get(
            `${apiSettings.endpoints.products}?type=${productType}`,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return [];
    }
}

/**
 * Get product by id
 *
 * @param {string} productId - Product ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Product>} - Product
 */
async function getProductById(productId: string, message: MessageApiInjection): Promise<Product> {
    try {
        const response = await axiosAPI.get(`${apiSettings.endpoints.products}/${productId}`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Purchase a user resume
 *
 * @param {string} userId - User ID
 * @param {MessageApiInjection} message - Message API
 * @param {boolean} isManagerSubscribed - Indicates if the manager is subscribed
 * @returns {Promise<ResumeStock>} - Bought resume
 */
async function buyResume(
    userId: string,
    message: MessageApiInjection,
    isManagerSubscribed: boolean
): Promise<ResumeStock> {
    try {
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.resumes}/${userId}/reveal`,
            null, // No request body for purchase, pass null
            {
                params: {
                    isManagerSubscribed,
                },
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

const productsApi = {
    getProducts,
    getProductById,
    buyResume,
};

export default productsApi;
