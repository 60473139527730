import { Address } from '@/model/generic';
import { Product } from '@/model/product';
import Utils from '@/utils/utils';

export const SalonCharacteristics = {
    INDEPENDENT: Utils.getLocalizedMessages().message.independant,
    FRANCHISED: Utils.getLocalizedMessages().message.franchised,
};

export const SalonSize = {
    MORE_THAN_THREE: Utils.getLocalizedMessages().message.moreThanThree,
    ZERO_TO_THREE: Utils.getLocalizedMessages().message.zeroToThree,
};

export const SalonType = {
    BARBER: Utils.getLocalizedMessages().message.barber,
    HIGH_COST: Utils.getLocalizedMessages().message.highCost,
    LOW_COST: Utils.getLocalizedMessages().message.lowCost,
    MID_COST: Utils.getLocalizedMessages().message.midCost,
    NEIGHBORHOOD_SHOP: Utils.getLocalizedMessages().message.neighborhoodShop,
};

export interface Subscription {
    expiracyDate: number;
    service: Product;
    renew: boolean;
}

export interface Salon {
    id?: string;
    owners: Array<string>;
    jobIds: Array<string>;
    name?: string;
    description?: string;
    type?: typeof SalonType;
    siren: string | undefined;
    siret: string | undefined;
    characteristics?: typeof SalonCharacteristics;
    pictures: Array<string>;
    phone?: string;
    email?: string;
    video?: string;
    creationDate?: number;
    size?: typeof SalonSize;
    active?: boolean;
    subscription?: Subscription;
    address: Address;
    jobPicture: string | undefined;
}

export interface SalonFilters {
    name?: string;
    city?: string;
    zipCode?: string;
    sort?: string;
}

export function defaultSalon(): Salon {
    return {
        siren: undefined,
        siret: undefined,
        owners: [],
        jobIds: [],
        pictures: [],
        address: {},
        jobPicture: undefined,
        active: true,
    };
}
