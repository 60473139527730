import { apiSettings, checkErrorStatus, checkResponseStatus, ErrorMessage } from '@/api/global';
import { axiosAPI } from '@/axiosInstance';
import { Order, OrderCreateRequest, ValidatedOrder } from '@/model/order';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { AxiosError } from 'axios';

/**
 * Get orders
 *
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Order>>} - Order List
 */
async function getOrders(message: MessageApiInjection): Promise<Array<Order>> {
    try {
        const response = await axiosAPI.get(apiSettings.endpoints.orders, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Get orders
 *
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Order>} - Order List
 */
async function getLatestValidatedOrder(message: MessageApiInjection): Promise<Order> {
    try {
        const response = await axiosAPI.get(`${apiSettings.endpoints.orders}/latest`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Get order by ID
 *
 * @param {string} orderId - Order ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Order>} - Order
 */
async function getOrderByExtRef(orderId: string, message: MessageApiInjection): Promise<Order> {
    try {
        const response = await axiosAPI.get(`${apiSettings.endpoints.orders}/${orderId}`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Create a new order for a subscription
 *
 * @param {string} salonId - Salon ID
 * @param {number} productId - Product ID
 * @param {boolean} renew - True to automatically renew subscription, false otherwise
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Order>} - Created order
 */
async function createSubscriptionOrder(
    salonId: string,
    productId: number,
    renew: boolean,
    message: MessageApiInjection
): Promise<Order> {
    try {
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.orders}/subscription`,
            {
                salonId,
                productId,
                renew,
            },
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 201, message);
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    403,
                    {
                        title: 'Erreur',
                        content: "Vous n'avez pas le droit de créer une commande pour ce salon",
                    },
                ],
                [
                    400,
                    {
                        title: 'Erreur',
                        content:
                            'Les informations du salon ou votre raison sociale ne sont pas renseigné chez nous. Veuillez compléter les informations',
                    },
                ],
            ])
        );
    }
}

/**
 * Create a new order to buy resumes
 *
 * @param {number} productId - The product ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Order>} - The created order
 */
async function createResumeOrder(productId: number, message: MessageApiInjection): Promise<Order> {
    try {
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.orders}/resumes`,
            { productId },
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 201, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Create a new order and send a payment email to the user
 *
 * @param {OrderCreateRequest} requestBody - Request body for order creation
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Order>} - Created order
 */
async function createPaymentOrder(
    requestBody: OrderCreateRequest,
    message: MessageApiInjection
): Promise<Order> {
    try {
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.orders}/subscription`,
            requestBody,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 201, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Validate an order to process payment
 *
 * @param {string} orderId - Order ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<ValidatedOrder>} - Validated order
 */
async function validateOrder(
    orderId: string,
    message: MessageApiInjection
): Promise<ValidatedOrder> {
    try {
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.orders}/${orderId}/validate`,
            null, // No request body for validation, pass null
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

const ordersApi = {
    getOrders,
    getLatestValidatedOrder,
    getOrderByExtRef,
    createSubscriptionOrder,
    validateOrder,
    createPaymentOrder,
    createResumeOrder,
};

export default ordersApi;
