import { Address } from '@/model/generic';
import { Job } from '@/model/job';
import { Salon } from '@/model/salon';
import Utils from '@/utils/utils';

export type ApplicationStatus = 'SENT' | 'SEEN' | 'INTERVIEW' | 'ACCEPTED' | 'REFUSED';

export interface Application {
    id: string;
    user: Applicant;
    job: Job;
    salon: Salon;
    status: ApplicationStatus;
    createdDate: number;
}

export interface ApplicationFilters {
    firstName?: string;
    lastName?: string;
    jobName?: string;
    salonZipCode?: string;
    userZipCode?: string;
    sort?: string;
}

export const EApplicationStatus = {
    SENT: Utils.getLocalizedMessages()?.message.new,
    SEEN: Utils.getLocalizedMessages()?.message.seen,
    INTERVIEW: Utils.getLocalizedMessages()?.message.interview,
    ACCEPTED: Utils.getLocalizedMessages()?.message.accepted,
    REFUSED: Utils.getLocalizedMessages()?.message.refused,
};

export interface JobApplications {
    jobName: string;
    applications: Array<Application>;
}

export interface Applicant {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    address: Address;
}
