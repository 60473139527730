import { Address, Enum, PageableResponse } from '@/model/generic';
import { Job } from '@/model/job';
import { routeConfig } from '@/router';
import { RadioButtonOff as OffIcon, RadioButtonOn as OnIcon } from '@vicons/ionicons5';
import { NIcon, SelectOption } from 'naive-ui';
import { DialogApiInjection } from 'naive-ui/es/dialog/src/DialogProvider';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { Component, h, VNodeChild } from 'vue';
import { RouteRecordName } from 'vue-router';
import { createErrorMessage } from '@/api/global';
import { ReviveScreenName } from '@/model/configuration';
import { userStore } from '@/store/user';
import i18n from '@/i18n/i18n';

/**
 * Render an icon
 * @param icon icon to render
 */
function renderIcon(icon: Component): VNodeChild {
    return h(NIcon, null, { default: () => h(icon) });
}

/**
 * @param source the source assets string in public directory
 */
function renderPicto(source: string): VNodeChild {
    return h('img', { src: source }, {});
}

/**
 * Generate the background for pages
 * @param layoutId the layout element id
 * @param routeName the route name
 */
function generateBackground(
    layoutId: 'wrapping-layout' | 'manager-layout',
    routeName: RouteRecordName
): void {
    const layout: HTMLElement | null = document.querySelector(`#${layoutId}`);
    if (layout) {
        let image = '';
        switch (routeName) {
            case routeConfig.ROUTE_SALONS_DETAILS.name:
            case routeConfig.ROUTE_MANAGED_SALONS_DETAILS.name:
            case routeConfig.ROUTE_ADMIN_SALONS_SUBSCRIPTION.name:
            case routeConfig.ROUTE_MANAGED_SALONS_SUBSCRIPTION.name:
            case routeConfig.ROUTE_PRODUCT.name: // FIXME: TEMPORARY BACKGROUND
            case routeConfig.ROUTE_MY_PROFILE.name: // FIXME: TEMPORARY BACKGROUND
                image = 'var(--details-background)';
                break;
            case routeConfig.ROUTE_SALONS_JOBS.name:
            case routeConfig.ROUTE_MANAGED_SALONS_JOBS.name:
            case routeConfig.ROUTE_JOBS_CREATE.name:
            case routeConfig.ROUTE_MANAGED_SALONS_APPLICANTS.name:
            case routeConfig.ROUTE_ADMIN_SALONS_APPLICANTS.name:
                image = 'var(--job-background)';
                break;
            case routeConfig.ROUTE_MANAGED_SALONS_OWNERS.name:
            case routeConfig.ROUTE_SALON_OWNERS.name:
                image = 'var(--owner-background)';
                break;
            case routeConfig.ROUTE_ADMIN_SALONS_PICTURES.name:
            case routeConfig.ROUTE_MANAGED_SALONS_PICTURES.name:
                image = 'var(--image-background)';
                break;
            case routeConfig.ROUTE_MANAGED_SALONS_VIDEO.name:
            case routeConfig.ROUTE_ADMIN_SALONS_VIDEO.name:
                image = 'var(--video-background)';
                break;
            case routeConfig.ROUTE_SALONS_CREATE.name:
                image = 'var(--salon-create-background)';
                break;
            case routeConfig.ROUTE_HOME.name:
                image = 'var(--home-background)';
                break;
            case routeConfig.ROUTE_PROSPECTS.name:
            case routeConfig.ROUTE_REAVEALED_RESUMES.name:
                image = 'var(--salon-create-background)';
                break;
        }
        layout.style.backgroundImage = image;
    }
}

/**
 * Render an address
 * @param address address to render
 */
function renderAddress(address: Address | undefined): VNodeChild {
    return address
        ? h('span', null, {
              default: () =>
                  `${address.street} ${address.zipCode} ${address.city}, ${address.country}`,
          })
        : undefined;
}

/**
 * Generate activation icon from value
 * @param active true if active false otherwise
 * @returns rendered icon
 */
function activeIcon(active: boolean | undefined): VNodeChild {
    return active ? Utils.renderIcon(OnIcon) : Utils.renderIcon(OffIcon);
}

/**
 * Convert an objet to a string or query parameters as &f1=v1&f2=v2
 * @param object object to convert
 * @param areInitialParameters true if those params are the first
 * @returns string as query parameters
 */
function objectToQueryParameters(object: object, areInitialParameters = false): string {
    // Build query parameters
    let queryParameters = '';
    Object.keys(object).forEach((parameter: string) => {
        const filterValue = object[parameter as keyof typeof object];
        if (filterValue) {
            queryParameters += `${!areInitialParameters ? '&' : ''}${parameter}=${filterValue}`;
        }
        areInitialParameters = false;
    });
    return queryParameters;
}

/**
 * Set value to path of object
 * @param element element on which set value
 * @param path comma separated path
 * @param value value to set
 * @returns
 */
// eslint-disable-next-line
function setObjectValue(object: any, path: string, value: unknown) {
    const segments: Array<string> = path.split('.');
    let cursor = object;
    let i = 0;

    for (i; i < segments.length - 1; ++i) {
        const segment = segments[i];
        cursor = cursor[segment] = cursor[segment] || {};
    }

    return (cursor[segments[i]] = value);
}

/**
 * Convert enum to selecto options
 * @param enumeration enum to convert
 * @returns selection option
 */
function enumToSelectOptions(enumeration: Enum | undefined): Array<SelectOption> {
    return enumeration === undefined
        ? []
        : Object.entries(enumeration).map((entry) => {
              return {
                  label: entry[1],
                  value: entry[0],
              };
          });
}

/**
 * Convert a date epoch to a date string
 * @param epoch date to convert
 * @returns date as string
 */
function renderDatetimeFromEpoch(epoch: number): string {
    return new Date(epoch).toLocaleDateString();
}

/**
 * Render a dialog to confirm the choice of the user
 * @param dialogApi a dialog Api injection
 * @param content the content in the alert dialog
 * @param positiveCallback the callback to execute when the positive button is clicked
 * @param message a message api injection if wou want to display messages
 */
function renderConfirmDialog(
    dialogApi: DialogApiInjection,
    content: string,
    positiveCallback: () => Promise<unknown>,
    message: MessageApiInjection | undefined = undefined
) {
    const dialog = dialogApi.warning({
        title: 'Êtes-vous sûr ?',
        content: content,
        positiveText: 'Oui',
        negativeText: 'Non',
        onPositiveClick: async () => {
            dialog.loading = true;
            if (message !== undefined) {
                message.loading('Opération en cours...');
            }
            try {
                await positiveCallback();
                if (message !== undefined) {
                    message.destroyAll();
                    message.success('Opération réussie');
                }
            } catch {
                if (message !== undefined) {
                    message.destroyAll();
                    createErrorMessage(
                        message,
                        'Une erreur est survenue, veuillez réessayer plus tard.'
                    );
                }
            }
        },
    });
}

/**
 * Compare the given date with the current date
 *
 * @param date the date to compare
 * @returns true if the given date is previous anterior false otherwise
 */
function isDateBeforeToday(date: number): boolean {
    const currentDate: Date = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const currentDateNumber: number = Number.parseInt(
        `${currentDate.getFullYear()}${month < 10 ? `0${month}` : month}${
            day < 10 ? `0${day}` : day
        }`
    );
    return date < currentDateNumber;
}

/**
 * Parse the basic date number (format yyyyMMdd) to a locale date string
 *
 * @param date the given date
 * @returns the formatted date string
 */
function basicDateToLocaleDateString(date: number): string {
    const strDate: string = date.toString();
    return new Date(
        Number.parseInt(strDate.substring(0, 4)),
        Number.parseInt(strDate.substring(4, 6)) - 1, // -1 because January is 0
        Number.parseInt(strDate.substring(6))
    ).toLocaleDateString();
}

/**
 * Format number to currency (euros)
 * @param value number to format
 * @returns the formatted currency
 */
function toCurrency(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    });

    return formatter.format(value);
}

/**
 * Download a file by creating virtual link and force clic on it
 * @param content file content
 * @param filename filename
 */
function downloadFile(content: Blob, filename: string): void {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(content);
    a.download = filename;
    a.click();
}

/**
 * Check if current job is published
 * @return true if published false otherwise
 */
function isJobPublished(job: Job): boolean {
    return job.status == 'PUBLISHED';
}

/**
 * Redirect the user to his default email client
 * @param email the destination email address
 * @param object the subject of the email
 */
function mailTo(email: string, object: string): void {
    window.open(`mailto:${email}?subject=${object}`, '_blank')?.focus();
}

export const defaultPageable = (): PageableResponse<any> => {
    return {
        content: [],
        first: false,
        empty: true,
        last: false,
        number: 0,
        numberOfElements: 0,
        pageable: {
            pageNumber: 0,
            pageSize: 0,
        },
        size: 0,
        sort: {
            empty: true,
            sorted: true,
            unsorted: true,
        },
        totalElements: 0,
        totalPages: 0,
    };
};

/**
 * Create the path to the publicity
 *
 * @param {ReviveScreenName} zoneName   The name zone of the publicity
 * @param {number} refreshDelay         The refresh delay
 */
function createPathPublicity(zoneName: ReviveScreenName, refreshDelay: number | undefined): string {
    const zoneId = userStore.state.publicity[zoneName];
    const refreshing: string = refreshDelay ? `&refresh=${refreshDelay}` : '';
    return `${process.env.VUE_APP_ADS_URL}/www/delivery/afr.php?zoneid=${zoneId}${refreshing}`;
}

function getLocalizedMessages() {
    switch (i18n.i18n.global.locale) {
        case 'de':
            return i18n.messages.de;
        case 'en':
            return i18n.messages.en;
        case 'es':
            return i18n.messages.es;
        case 'fr':
            return i18n.messages.fr;
        default:
            return i18n.messages.fr;
    }
}

const Utils = {
    renderIcon,
    renderAddress,
    activeIcon,
    objectToQueryParameters,
    setObjectValue,
    enumToSelectOptions,
    renderDatetimeFromEpoch,
    renderConfirmDialog,
    isDateBeforeToday: isDateBeforeToday,
    basicDateToLocaleDateString,
    toCurrency,
    downloadFile,
    isJobPublished,
    renderPicto,
    generateBackground,
    mailTo,
    defaultPageable,
    createPathPublicity,
    getLocalizedMessages,
};

export default Utils;
