/* FOR FUTURE DEVS 
Please follow these instructions:

1) Separate as much as you can, pages, components, model fields...
2) Use alphabetical sort in message objects and also for variables names
(Why ? -> Because this file will grow, devs usage will be either ctrl+f to find the id OR find a specific file (page, components...) 
and specific id from this page, the alphabetic sort will help to find more easier the searched id)
3) Use camelCase


When you add new keys/value in a file, also add it in another translation files (with empty value if you dont have the translation)
(useful : regex to delete all values to create a template '[^']*'|"[^"]*" )

Thanks you !
Tavernier Lucas 
*/

/*------------------*/

/* Globals | Models */

/*------------------*/

const global = {
    appUrl: 'zestee.pro',
    characteristics: 'Caractéristiques',
    choose: 'Choisir',
    confirm: 'Confirmer',
    contact: 'Contacter',
    delete: 'Supprimer',
    description: 'Description',
    deselect: 'Désélectionner',
    disconnect: 'Se déconnecter',
    duration: 'Durée',
    level: 'Niveau',
    modality: 'Modalité',
    no: 'Non',
    type: 'Type',
    update: 'Mettre à jour',
    wording: 'Libellé',
    yes: 'Oui',
};

const contract = {
    apprenticeshipContract: "Contrat d'apprentissage",
    fixedTermContract: 'CDD',
    permanentContract: 'CDI',
};

const contractModality = {
    fullTime: 'Temps plein',
    partTime: 'Temps partiel',
};

const eApplicationStatus = {
    accepted: 'Accepté',
    interview: 'Entretien',
    new: 'Nouvelle',
    refused: 'Refusé',
    seen: 'Vu',
};

const geographicAvailability = {
    indifferent: 'Indifférent',
};

const hairdresserLevel = {
    apprentice: 'Apprenti',
    barber: 'Barbier',
    beginner: 'Débutant',
    experienced: 'Expérimenté',
    hairdresserManager: 'Manager',
    trainer: 'Formateur',
};

const invoiceStatus = {
    paid: 'Payée',
    toPay: 'À Payer',
};

const jobStatus = {
    hidden: 'Masquée',
    published: 'Publiée',
};

const missionDurationType = {
    month: 'Mois',
    year: 'Années',
};

const requiredExperience = {
    oneYear: '1 an',
    twoYears: '2 ans',
    threeYears: '3 ans',
    fourYears: '4 ans',
    fiveYears: '5 ans',
    moreThanFiveYears: 'Plus de 5 ans',
};

const role = {
    admin: 'admin',
    salon: 'salon',
};

const salonCharacteristics = {
    independant: 'Indépendant',
    franchised: 'Franchisé',
};

const salonSize = {
    moreThanThree: 'Plus de 3 salariés',
    zeroToThree: 'De 0 à 3 salariés',
};

const salonType = {
    highCost: 'Salon de luxe',
    lowCost: 'Low cost',
    midCost: 'Milieu de gamme',
    neighborhoodShop: 'Salon de quartier',
};

const skillType = {
    personal: 'Personnel',
    relational: 'Relationnel',
    technical: 'Technique',
};

const spokenLanguageLevel = {
    bilingual: 'Bilingue',
    intermediate: 'Intermédiaire',
    native: 'Natale',
    notions: 'Notions',
};

const userAddress = {
    address: 'Adresse',
    city: 'Ville',
    country: 'Pays',
    zipcode: 'Code Postal',
};

const userExperiences = {
    currentPost: 'Poste actuel',
    experienceLevel: "Niveau d'expérience",
    experiences: 'Expériences',
    jobEndDate: 'Date de fin',
    jobEndDatePlaceholder: 'Date de fin de contrat',
    newExperience: 'Nouvelle expérience',
    noExperienceFilled: 'Aucune expérience renseignée',
};

const userFields = {
    birthdate: 'Date de naissance',
    brandsWorkWith: 'Marques utilisés',
    email: 'Email',
    firstName: 'Prénom',
    formations: 'Formations',
    hiddenAccount: 'Compte masqué',
    lastName: 'Nom',
    password: 'Mot de passe',
    phoneNumber: 'Numéro de téléphone',
    socialReason: 'Raison Sociale',
};

const userLanguages = {
    language: 'Langue',
    newLanguage: 'Nouvelle langue',
    noLanguageFilled: 'Aucune langue renseignée.',
    spokenLanguages: 'Langues parlés',
};

const userSkills = {
    newSkill: 'Nouvelle compétence',
    noSkillFilled: 'Aucune compétence renseignée',
    skills: 'Compétences',
    skillType: 'Type de compétence',
};

/*-------*/

/* PAGES */

/*-------*/

const adminSalonPage = {
    adminCreateNewSalon: 'Créer un nouveau salon',
};

const adminStatsPage = {
    statistics: 'Stastistiques',
};

const adminUserPage = {
    adminCreateNewHairdresser: 'Créer un coiffeur',
    adminCreateNewManager: 'Créer un employeur',
};

const createHairdresserPage = {
    createHairdresser: 'Créer le coiffeur',
    createNewHairdresser: 'Nouveau coiffeur',
};

const createJobPage = {
    createJob: "Créer l'annonce",
    createNewJob: 'CRÉER UNE NOUVELLE ANNONCE !',
};

const createManagerPage = {
    createManager: "Créer l'employeur",
    createNewManager: 'CRÉER UN NOUVEL EMPLOYEUR !',
};

const createSalonPage = {
    createNewSalon: 'CRÉER UN NOUVEAU SALON !',
    createSalon: 'Créer le salon',
};

const hairdresserOrManagerDetailsPage = {
    accessToBackofficeSalon: 'Accès au backoffice salon :',
    accessToBackofficeAdmin: 'Accès aux espaces administrateurs :',
    permissions: 'Permissions',
    personalInformations: 'Informations personnelles',
    portfolio: 'Portfolio',
    video: 'Vidéo',
};

const homePage = {
    createdByHairdressers: 'CRÉÉE PAR DES COIFFEURS',
    createdForHairdressers: 'POUR DES COIFFEURS',
    myAccount: 'Mon compte',
    myJobApplications: 'Mes candidatures',
    myJobOffers: 'Mes annonces',
    mySalons: 'Mes salons',
};

const loginPage = {
    askNewUser: 'Nouvel utilisateur ?',
    askPasswordForgotten: 'Mot de passe oublié ?',
    connect: 'Connexion',
    connectOnto: 'Se connecter sur',
    register: "S'enregistrer",
    registration: 'Enregistrement',
    resetting: 'Réinitialisation',
};

const managedSalonsDetailsPage = {
    createFirstSalon: 'Enregistrez votre premier salon.',
    createMySalon: 'Je crée mon salon',
    welcomeToBackoffice: 'Bienvenu(e) sur Zestee Pro,',
};

const myProfilePage = {
    myProfile: 'Mon profil',
};

const notFoundPage = {
    404: '404 - Page introuvable',
    backToHome: "Revenir à l'accueil",
    searchNotFound: 'La page que vous cherchez actuellement est introuvable',
};

const passwordModifyPage = {
    weAreSorry: 'Nous sommes désolés...',
};

const passwordRequestPage = {
    backToLogin: 'Retour à la page de connexion',
    confirmNewPassword: 'Confirmation du mot de passe',
    expiredAction: 'Action expirée.',
    newPassword: 'Nouveau mot de passe',
    submit: 'Envoyer',
    updatePassword: 'Mise à jour du mot de passe',
};

const productsPage = {
    buyResumes: 'Achat de CVs Zestee',
    goodSearch: 'Bonne recherche !',
    iSubscribe: "Je m'abonne",
    notSubscribedAlert: 'Acheter un abonnement vous permet de consulter tous les CVs de Zestee',
    subscribedAlert: "En tant qu'abonné vous pouvez consulter tous les CV's gratuitement.",
    resumeStockRemaining:
        "Vous n'avez actuellement aucun stock de CV | Il ne vous reste qu'un seul CV dans votre stock ! | {'Vous avez actuellement un stock de'} {remainingStock} CV's",
};

const registerPage = {
    acceptCgu: {
        //Not alphabetical because it's the sentence order
        readAndAccept: "J'ai lu et j'accepte les termes des",
        cgu: "conditions générales d'utilisation",
        and: 'et',
        privacyPolicy: 'de la politique de confidentialité',
    },
    askAlreadyRegistered: 'Déjà inscrit ?',
    registerOn: "S'inscrire sur @:message.appUrl",
};

const revealedResumesPage = {
    myResumes: "Mes CV's",
};

const salonsDetailsPage = {
    applicants: 'Candidats',
    addManager: 'Ajouter un manager',
    createNewJob: 'CRÉER UNE NOUVELLE ANNONCE',
    details: 'Détails',
    deleteSalon: 'Supprimer le salon',
    images: 'Images',
    jobHidden: 'Cachée',
    jobOffers: "Offres d'emploi",
    jobPublished: 'Publiée',
    noApplicationForTheMoment: "Vous n'avez aucune candidature pour le moment",
    salonManagement: 'Gestion du salon',
    salonManagers: 'Propriétaires du salon',
    status: 'Statut',
    subscription: 'Abonnement',
    subscriptionRequired: "Un abonnement est nécessaire afin d'accéder à cette fonctionnalité.",
};

const searchEmployeesPage = {
    notSubscribedInfo: "Pas encore abonnés, vous avez accès au Cv's dans la rubrique",
    resumesLibrary: 'CVthèque',
    subscribedInfo: "Abonnés, vous pouvez consulter les CV's gratuitement.",
};

const thanksPage = {
    backToSalonSubscription: "Retourner sur la page d'abonnement du salon",
    invoiceDownload: 'Télécharger votre facture',
    succesfulPayment: 'Votre paiement a été traité avec succès',
};

/*------------*/

/* COMPONENTS */

/*------------*/

const booleanSwitchComponent = {
    authorized: 'Autorisé',
    refused: 'Refusé',
};

const jobFormComponent = {
    contractDuration: 'Durée du contrat',
    contractDurationPlaceholder: 'Mois, années',
    contractModality: 'Modalité de contrat',
    contractType: 'Type de contrat',
    grossSalary: 'Salaire brut',
    jobDescription: 'Description du poste',
    jobStartDate: 'Date de début',
    jobStartDatePlaceholder: 'Date de début du contrat',
    jobTitle: 'Intitulé du poste',
    requiredExperience: 'Expérience requise',
    requiredLevel: 'Niveau requis',
    requiredYears: 'Années requises',
};

const orderComponent = {
    proceedToPayment: 'Passer au paiement',
    totalAmount: 'Montant total :',
};

const pictureListManagerComponent = {
    max: 'MAX :',
    stockageUnit: 'MB',
    dragAndDropOrSelectImage: 'GLISSER-DÉPOSER OU SÉLECTIONNER UNE IMAGE',
    dragAndDropOrSelectVideo: 'GLISSER-DÉPOSER OU SÉLECTIONNER UNE VIDEO',
};

const productComponent = {
    buy: 'Acheter',
    exclTax: '{productPrice} € HT',
    exclTaxByMonth: '{productPrice} € HT / mois ',
    exclTaxByYear: '{productPrice} € HT / an',
    exclTaxForNMonth: '{productPrice} € HT pour {productDuration} mois',
    mensualSubscription: 'Abonnement mensuel sans engagement de durée',
    resiliation: 'Résiliez directement depuis votre espace abonnement',
};

const salonFormComponent = {
    activeSalonPlaceholder:
        'Désactivez ici la publication de votre salon si vous avez besoin de suspendre les annonces de celui-ci.',
    descriptionPlaceholder:
        "Décrivez en quelques phrases votre salon, vous pouvez parler de votre concept, de votre vision, de la décoration, du matériel dont vous disposez, de l'ambiance, de l'équipe...",
    salonCharacteristics: 'Caractéristiques du salon',
    salonName: 'Nom du salon',
    salonSize: 'Taille du salon',
    salonStatus: 'Salon {status}',
    salonStatusActive: 'actif',
    salonStatusInactive: 'inactif',
    salonType: 'Type de salon',
    siren: 'SIREN',
    siret: 'SIRET',
};

const subscriptionComponent = {
    automaticRenew: 'Renouvelé automatiquement',
    notRenew: 'Non renouvelé',
    payerMail: 'Email du payeur',
    selectSubscription: 'Sélectionnez un abonnement',
    sendPaymentOrder: "Envoyer l'ordre de paiement",
    subscriptionNeedSiretSiren: 'Vous devez renseigner votre SIRET ou SIREN avant de vous abonner',
    subscriptionNeedSocialReason: 'Vous devez renseigner votre raison sociale pour vous abonner',
    subscriptionReactivation: "Réactiver l'abonnement",
    subscriptionResiliation: "Résilier l'abonnement",
};

const cancelSubscriptionOpinionComponent = {
    helpUsToUnderstandTheCancellation:
        'Nous cherchons à nous améliorer, pouvez-vous nous donner la raison de votre résiliation ?',
    iDidNotFindJob: "Je n'ai pas trouvé de collaborateur·trice.",
    iFindJobNotThanksToZestee: "J'ai trouvé un·e collaborateur·trice en dehors de Zestee",
    iFindJobThanksToZestee: "J'ai trouvé un·e collaborateur·trice grâce à Zestee.",
    other: 'Autre :',
};

const userResumeComponent = {
    creations: 'Créations',
    since: 'Depuis le',
};

/*************/

const messagesFr = {
    //globals
    ...global,
    ...contract,
    ...contractModality,
    ...eApplicationStatus,
    ...geographicAvailability,
    ...hairdresserLevel,
    ...invoiceStatus,
    ...jobStatus,
    ...missionDurationType,
    ...requiredExperience,
    ...role,
    ...salonCharacteristics,
    ...salonSize,
    ...salonType,
    ...skillType,
    ...spokenLanguageLevel,
    ...userAddress,
    ...userExperiences,
    ...userFields,
    ...userLanguages,
    ...userSkills,
    //pages
    ...adminSalonPage,
    ...adminStatsPage,
    ...adminUserPage,
    ...createHairdresserPage,
    ...createJobPage,
    ...createManagerPage,
    ...createSalonPage,
    ...hairdresserOrManagerDetailsPage,
    ...homePage,
    ...loginPage,
    ...managedSalonsDetailsPage,
    ...myProfilePage,
    ...notFoundPage,
    ...passwordModifyPage,
    ...passwordRequestPage,
    ...productsPage,
    ...registerPage,
    ...revealedResumesPage,
    ...salonsDetailsPage,
    ...searchEmployeesPage,
    ...thanksPage,
    //components
    ...booleanSwitchComponent,
    ...cancelSubscriptionOpinionComponent,
    ...jobFormComponent,
    ...orderComponent,
    ...pictureListManagerComponent,
    ...productComponent,
    ...salonFormComponent,
    ...subscriptionComponent,
    ...userResumeComponent,
};

export default messagesFr;
