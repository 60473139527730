import { Order } from '@/model/order';
import { Salon } from '@/model/salon';
import router, { routeConfig } from '@/router';
import { userStore } from '@/store/user';
import { RouteLocationRaw } from 'vue-router';

/**
 * Check if authenticated user is admin, if not return home route
 * @returns true if admin, false otherwise and return home route
 */
function adminOrRedirect(): boolean | RouteLocationRaw {
    return userStore.getters.isAdmin ? true : routeConfig.ROUTE_HOME;
}

/**
 * Check  is salon owner or redirect
 * @param salonId salon id
 */
async function salonOwnerOrRedirect(salonId: string): Promise<void> {
    // Redirect if not in owned salons
    if (userStore.state.ownedSalons.filter((salon: Salon) => salon.id === salonId).length === 0) {
        router.push({
            name: routeConfig.ROUTE_HOME.name,
        });
    }
}

async function isOrderOwnerOrRedirect(order: Order) {
    if (order.ownerId !== userStore.state.userInfo.id) {
        router.push({ name: routeConfig.ROUTE_HOME.name });
    }
}

/**
 * Check if is admin or is salon owner or redirect
 * @param salonId salon, id
 */
async function adminOrSalonOwnerOrRedirect(salonId: string): Promise<void> {
    if (
        userStore.getters.isAdmin ||
        userStore.state.ownedSalons.filter((salon: Salon) => salon.id === salonId).length > 0
    ) {
        return;
    }

    // Redirect
    router.push({
        name: routeConfig.ROUTE_HOME.name,
    });
}

const Guards = {
    adminOrRedirect,
    salonOwnerOrRedirect,
    adminOrSalonOwnerOrRedirect,
    isOrderOwnerOrRedirect,
};

export default Guards;
