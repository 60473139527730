import { apiSettings, checkErrorStatus, checkResponseStatus, ErrorMessage } from '@/api/global';
import { MediaType, PageableResponse, PageSettings } from '@/model/generic';
import {
    EmployeesFilters,
    Hairdresser,
    Manager,
    ManagerCreateRequest,
    Resume,
    Role,
    User,
    UserFilters,
} from '@/model/user';
import Utils from '@/utils/utils';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { axiosAPI } from '@/axiosInstance';
import { AxiosError } from 'axios';
import { event } from 'vue-gtag';

/**
 * Get user information
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<User>} - User information
 */
async function getUserInfo(message?: MessageApiInjection): Promise<User> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.users}/me`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        if (message) {
            return checkErrorStatus(error as AxiosError, message);
        }
        throw error; // Rethrow the error if needed
    }
}

/**
 * Get a hairdresser page
 * @param {PageSettings} pageSettings - Page settings
 * @param {MessageApiInjection} message - Message API
 * @param {UserFilters} filters - Filters
 * @returns {Promise<PageableResponse<Hairdresser>>} - User page
 */
async function getHairdressers(
    pageSettings: PageSettings,
    message: MessageApiInjection,
    filters: UserFilters = {}
): Promise<PageableResponse<Hairdresser>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.hairdressers}`, {
            params: {
                size: pageSettings.pageSize,
                page: pageSettings.pageNumber,
                ...filters,
            },
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return Utils.defaultPageable();
    }
}

/**
 * Create a new manager
 * @param {ManagerCreateRequest} manager - The manager to create
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Manager>} - The created manager
 */
async function createManager(
    manager: ManagerCreateRequest,
    message: MessageApiInjection
): Promise<Manager> {
    try {
        await getXsfr();

        // Make the Axios request without explicit headers
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.managers}/register`,
            manager,
            {
                responseType: 'json',
                withXSRFToken: true,
                withCredentials: true,
            }
        );

        event('sign_up', {
            method: 'Backoffice',
        });

        return checkResponseStatus(response, 201, message);
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    409,
                    {
                        title: 'Erreur de création',
                        content: 'Cette adresse email est déjà utilisée',
                    },
                ],
            ])
        );
    }
}

/**
 * Create a new hairdresser
 * @param {ManagerCreateRequest} hairdresser - The hairdresser to create
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Hairdresser>} - The created hairdresser
 */
async function createHairdresser(
    hairdresser: User,
    message: MessageApiInjection
): Promise<Hairdresser> {
    try {
        await getXsfr();

        // Make the Axios request without explicit headers
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.hairdressers}/register`,
            hairdresser,
            {
                responseType: 'json',
                withXSRFToken: true,
                withCredentials: true,
            }
        );

        event('sign_up', {
            method: 'Backoffice',
        });

        return checkResponseStatus(response, 201, message);
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    409,
                    {
                        title: 'Erreur de création',
                        content: 'Cette adresse email est déjà utilisée',
                    },
                ],
            ])
        );
    }
}

async function getXsfr() {
    try {
        await axiosAPI.post(`${apiSettings.endpoints.users}/xsrf`, null, {
            withCredentials: true,
            xsrfHeaderName: 'X-XSRF-TOKEN',
            xsrfCookieName: 'XSRF-TOKEN',
        });
    } catch (error) {
        return;
    }
}

/**
 * Get a manager page
 * @param {PageSettings} pageSettings - Page settings
 * @param {MessageApiInjection} message - Message API
 * @param {UserFilters} filters - Filters
 * @returns {Promise<PageableResponse<Manager>>} - User page
 */
async function getManagers(
    pageSettings: PageSettings,
    message: MessageApiInjection,
    filters: UserFilters = {}
): Promise<PageableResponse<Manager>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.managers}`, {
            params: {
                size: pageSettings.pageSize,
                page: pageSettings.pageNumber,
                ...filters,
            },
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return Utils.defaultPageable();
    }
}

/**
 * Add role to user
 * @param {Role} role - Role to add
 * @param {string} userId - User id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Role>>} - List of effective roles
 */
async function addRole(
    role: typeof Role,
    userId: string,
    message: MessageApiInjection
): Promise<Array<typeof Role>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.put(
            `${apiSettings.endpoints.users}/${userId}/roles/${role}`,
            null,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    404,
                    {
                        title: "Impossible d'ajouter le rôle",
                        content:
                            'Une erreur est survenue pendant la mise à jour, veuillez réessayer plus tard',
                    },
                ],
            ])
        );
    }
}

/**
 * Remove role from user
 * @param {Role} role - Role to remove
 * @param {string} userId - User id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Role>>} - List of effective roles
 */
async function removeRole(
    role: typeof Role,
    userId: string,
    message: MessageApiInjection
): Promise<Array<typeof Role>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.delete(
            `${apiSettings.endpoints.users}/${userId}/roles/${role}`,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    404,
                    {
                        title: 'Impossible de supprimer le rôle',
                        content:
                            'Une erreur est survenue pendant la mise à jour, veuillez réessayer plus tard',
                    },
                ],
            ])
        );
    }
}

/**
 * Get user by id
 * @param {string} id - User id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<T>} - User
 */
async function getUserById<T extends User>(id: string, message: MessageApiInjection): Promise<T> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.users}/${id}`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [404, { title: 'Erreur', content: "L'utilisateur n'existe pas" }],
            ])
        );
    }
}

/**
 * Update a user
 * @param {T} user - The user
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<T>} - A promise of the updated user
 */
async function updateUser<T extends User>(user: T, message: MessageApiInjection): Promise<T> {
    try {
        // Determine the endpoint based on the user type
        const endpoint = user.isManager
            ? apiSettings.endpoints.managers
            : apiSettings.endpoints.hairdressers;

        // Make the Axios request without explicit headers
        const response = await axiosAPI.put(`${endpoint}/${user.id}`, user, {
            responseType: 'json',
        });

        event('profile', {
            method: 'Backoffice',
            type: 'update',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Delete a user
 * @param {string} id - User ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<void>} - Empty promise
 */
async function remove(id: string, message: MessageApiInjection): Promise<void> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.delete(`${apiSettings.endpoints.users}/${id}`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 204, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Delete the user given media
 * @param {string} userId - The owner id
 * @param {MediaType} mediaType - The media type
 * @param {MessageApiInjection} message - Message API
 * @param {string} mediaPath - The media path
 * @returns {Promise<void>} - A promise that resolves when the picture has been deleted
 */
async function deleteMedia(
    userId: string,
    mediaType: MediaType,
    message: MessageApiInjection,
    mediaPath?: string
): Promise<void> {
    try {
        let url = `${apiSettings.endpoints.users}/${userId}/media/${mediaType}`;
        if (mediaPath) {
            url += `?filepath=${mediaPath}`;
        }

        // Make the Axios request without explicit headers
        const response = await axiosAPI.delete(url, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Search a resume
 * @param {EmployeesFilters} filters - Filters
 * @param {PageSettings} pageSettings - Page settings
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<PageableResponse<Resume>>} - Found resumes
 */
async function searchResume(
    filters: EmployeesFilters,
    pageSettings: PageSettings,
    message: MessageApiInjection
): Promise<PageableResponse<Resume>> {
    try {
        const url = `${apiSettings.endpoints.resumes}?${Utils.objectToQueryParameters(filters)}`;

        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(url, {
            responseType: 'json',
            params: {
                size: pageSettings.pageSize,
                page: pageSettings.pageNumber,
            }, // Include filters as params
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    404,
                    {
                        title: 'Erreur',
                        content: "Aucun CV n'est présent",
                    },
                ],
            ])
        );

        return Utils.defaultPageable();
    }
}

/**
 * Get the user-owned resumes
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Hairdresser>>} - The user-owned resumes
 */
async function getOwnedResumes(message: MessageApiInjection): Promise<Array<Hairdresser>> {
    try {
        const url = `${apiSettings.endpoints.managers}/resumes`;

        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(url, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

const userApi = {
    getUserInfo,
    getHairdressers,
    getManagers,
    getUserById,
    updateUser,
    remove,
    deleteMedia,
    addRole,
    removeRole,
    createManager,
    createHairdresser,
    searchResume,
    getOwnedResumes,
    getXsfr,
};

export default userApi;
