export interface Product {
    id: number;
    label: string;
    type: ProductType;
    reference: string;
    price: string;
    tvaPercentage: string;
    priceWithTaxes: number;
    image: string;
    duration: number;
}

export enum ProductType {
    PRODUCT = 'product',
    SERVICE = 'service',
}

export type ProductTypeString = 'product' | 'service';

export interface ResumeStock {
    userId: string;
    stock: number;
}
