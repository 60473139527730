import { apiSettings, checkErrorStatus, checkResponseStatus } from '@/api/global';
import { axiosAPI } from '@/axiosInstance';
import { Suggestions } from '@/model/generic';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { AxiosError } from 'axios';

/**
 * Query suggestions from API
 * @param {Suggestions} suggestionsField - The field of suggestions
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<string>>} - Array of suggestions
 */
async function getSuggestions(
    suggestionsField: Suggestions,
    message: MessageApiInjection
): Promise<Array<string>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(
            `${apiSettings.endpoints.suggestions}/${suggestionsField}`,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return [];
    }
}

const suggestionsApi = {
    getSuggestions,
};

export default suggestionsApi;
