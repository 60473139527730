/* FOR FUTURE DEVS 
Please follow these instructions:

1) Separate as much as you can, pages, components, model fields...
2) Use alphabetical sort in message objects and also for variables names
(Why ? -> Because this file will grow, devs usage will be either ctrl+f to find the id OR find a specific file (page, components...) 
and specific id from this page, the alphabetic sort will help to find more easier the searched id)
3) Use camelCase


When you add new keys/value in a file, also add it in another translation files (with empty value if you dont have the translation)
(useful : regex to delete all values to create a template '']*'|'']*" )

Thanks you !
Tavernier Lucas 
*/

/*------------------*/

/* Globals | Models */

/*------------------*/

const global = {
    appUrl: '',
    characteristics: '',
    choose: '',
    confirm: '',
    contact: '',
    delete: '',
    description: '',
    deselect: '',
    disconnect: '',
    duration: '',
    level: '',
    modality: '',
    no: '',
    type: '',
    update: '',
    wording: '',
    yes: '',
};

const contract = {
    apprenticeshipContract: '',
    fixedTermContract: '',
    permanentContract: '',
};

const contractModality = {
    fullTime: '',
    partTime: '',
};

const eApplicationStatus = {
    accepted: '',
    interview: '',
    new: '',
    refused: '',
    seen: '',
};

const geographicAvailability = {
    indifferent: '',
};

const hairdresserLevel = {
    apprentice: '',
    barber: '',
    beginner: '',
    experienced: '',
    hairdresserManager: '',
    trainer: '',
};

const invoiceStatus = {
    paid: '',
    toPay: '',
};

const jobStatus = {
    hidden: '',
    published: '',
};

const missionDurationType = {
    month: '',
    year: '',
};

const requiredExperience = {
    oneYear: '',
    twoYears: '',
    threeYears: '',
    fourYears: '',
    fiveYears: '',
    moreThanFiveYears: '',
};

const role = {
    admin: '',
    salon: '',
};

const salonCharacteristics = {
    independant: '',
    franchised: '',
};

const salonSize = {
    moreThanThree: '',
    zeroToThree: '',
};

const salonType = {
    highCost: '',
    lowCost: '',
    midCost: '',
    neighborhoodShop: '',
};

const skillType = {
    personal: '',
    relational: '',
    technical: '',
};

const spokenLanguageLevel = {
    bilingual: '',
    intermediate: '',
    native: '',
    notions: '',
};

const userAddress = {
    address: '',
    city: '',
    country: '',
    zipcode: '',
};

const userExperiences = {
    currentPost: '',
    experienceLevel: '',
    experiences: '',
    jobEndDate: '',
    jobEndDatePlaceholder: '',
    newExperience: '',
    noExperienceFilled: '',
};

const userFields = {
    birthdate: '',
    brandsWorkWith: '',
    email: '',
    firstName: '',
    formations: '',
    hiddenAccount: '',
    lastName: '',
    password: '',
    phoneNumber: '',
    socialReason: '',
};

const userLanguages = {
    language: '',
    newLanguage: '',
    noLanguageFilled: '',
    spokenLanguages: '',
};

const userSkills = {
    newSkill: '',
    noSkillFilled: '',
    skills: '',
    skillType: '',
};

/*-------*/

/* PAGES */

/*-------*/

const adminSalonPage = {
    createNewSalon: '',
};

const adminStatsPage = {
    statistics: '',
};

const adminUserPage = {
    createNewHairdresser: '',
    createNewManager: '',
};

const createHairdresserPage = {
    createHairdresser: '',
    createNewHairdresser: '',
};

const createJobPage = {
    createJob: '',
    createNewJob: '',
};

const createManagerPage = {
    createManager: '',
    createNewManager: '',
};

const createSalonPage = {
    createNewSalon: '',
    createSalon: '',
};

const hairdresserOrManagerDetailsPage = {
    accessToBackofficeSalon: '',
    accessToBackofficeAdmin: '',
    permissions: '',
    personalInformations: '',
    portfolio: '',
    video: '',
};

const homePage = {
    createdByHairdressers: '',
    createdForHairdressers: '',
    myAccount: '',
    myJobApplications: '',
    myJobOffers: '',
    mySalons: '',
};

const loginPage = {
    askNewUser: '',
    askPasswordForgotten: '',
    connect: '',
    connectOnto: '',
    register: '',
    registration: '',
    resetting: '',
};

const managedSalonsDetailsPage = {
    createFirstSalon: '',
    createMySalon: '',
    welcomeToBackoffice: '',
};

const myProfilePage = {
    myProfile: '',
};

const notFoundPage = {
    404: '',
    backToHome: '',
    searchNotFound: '',
};

const passwordModifyPage = {
    weAreSorry: '',
};

const passwordRequestPage = {
    backToLogin: '',
    confirmNewPassword: '',
    expiredAction: '',
    newPassword: '',
    submit: '',
    updatePassword: '',
};

const productsPage = {
    buyResumes: '',
    goodSearch: '',
    iSubscribe: '',
    notSubscribedAlert: '',
    subscribedAlert: '',
    resumeStockRemaining: '',
};

const registerPage = {
    acceptCgu: {
        //Not alphabetical because it's the sentence order
        readAndAccept: '',
        cgu: '',
        and: '',
        privacyPolicy: '',
    },
    askAlreadyRegistered: '',
    registerOn: '',
};

const revealedResumesPage = {
    myResumes: '',
};

const salonsDetailsPage = {
    applicants: '',
    addManager: '',
    createNewJob: '',
    details: '',
    deleteSalon: '',
    images: '',
    jobHidden: '',
    jobOffers: '',
    jobPublished: '',
    noApplicationForTheMoment: '',
    salonManagement: '',
    salonManagers: '',
    status: '',
    subscription: '',
    subscriptionRequired: '',
};

const searchEmployeesPage = {
    notSubscribedInfo: '',
    resumesLibrary: '',
    subscribedInfo: '',
};

const thanksPage = {
    backToSalonSubscription: '',
    invoiceDownload: '',
    succesfulPayment: '',
};

/*------------*/

/* COMPONENTS */

/*------------*/

const booleanSwitchComponent = {
    authorized: '',
    refused: '',
};

const jobFormComponent = {
    contractDuration: '',
    contractDurationPlaceholder: '',
    contractModality: '',
    contractType: '',
    grossSalary: '',
    jobDescription: '',
    jobStartDate: '',
    jobStartDatePlaceholder: '',
    jobTitle: '',
    requiredExperience: '',
    requiredLevel: '',
    requiredYears: '',
};

const orderComponent = {
    proceedToPayment: '',
    totalAmount: '',
};

const pictureListManagerComponent = {
    max: '',
    stockageUnit: '',
    dragAndDropOrSelectImage: '',
    dragAndDropOrSelectVideo: '',
};

const productComponent = {
    buy: '',
    exclTax: '',
    exclTaxByMonth: '',
    exclTaxByYear: '',
    exclTaxForNMonth: '',
    mensualSubscription: '',
    resiliation: '',
};

const salonFormComponent = {
    activeSalonPlaceholder: '',
    descriptionPlaceholder: '',
    salonCharacteristics: '',
    salonName: '',
    salonSize: '',
    salonStatus: '',
    salonStatusActive: '',
    salonStatusInactive: '',
    salonType: '',
    siren: '',
    siret: '',
};

const subscriptionComponent = {
    automaticRenew: '',
    notRenew: '',
    payerMail: '',
    selectSubscription: '',
    sendPaymentOrder: '',
    subscriptionNeedSiretSiren: '',
    subscriptionNeedSocialReason: '',
    subscriptionReactivation: '',
    subscriptionResiliation: '',
};

const cancelSubscriptionOpinionComponent = {
    helpUsToUnderstandTheCancellation: '',
    iDidNotFindJob: '',
    iFindJobNotThanksToZestee: '',
    iFindJobThanksToZestee: '',
    other: '',
};

const userResumeComponent = {
    creations: '',
    since: '',
};

/*************/

const messagesEn = {
    //globals
    ...global,
    ...contract,
    ...contractModality,
    ...eApplicationStatus,
    ...geographicAvailability,
    ...hairdresserLevel,
    ...invoiceStatus,
    ...jobStatus,
    ...missionDurationType,
    ...requiredExperience,
    ...role,
    ...salonCharacteristics,
    ...salonSize,
    ...salonType,
    ...skillType,
    ...spokenLanguageLevel,
    ...userAddress,
    ...userExperiences,
    ...userFields,
    ...userLanguages,
    ...userSkills,
    //pages
    ...adminSalonPage,
    ...adminStatsPage,
    ...adminUserPage,
    ...createHairdresserPage,
    ...createJobPage,
    ...createManagerPage,
    ...createSalonPage,
    ...hairdresserOrManagerDetailsPage,
    ...homePage,
    ...loginPage,
    ...managedSalonsDetailsPage,
    ...myProfilePage,
    ...notFoundPage,
    ...passwordModifyPage,
    ...passwordRequestPage,
    ...productsPage,
    ...registerPage,
    ...revealedResumesPage,
    ...salonsDetailsPage,
    ...searchEmployeesPage,
    ...thanksPage,
    //components
    ...booleanSwitchComponent,
    ...cancelSubscriptionOpinionComponent,
    ...jobFormComponent,
    ...orderComponent,
    ...pictureListManagerComponent,
    ...productComponent,
    ...salonFormComponent,
    ...subscriptionComponent,
    ...userResumeComponent,
};

export default messagesEn;
