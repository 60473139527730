import { createStore, Store } from 'vuex';
import { InjectionKey } from 'vue';

export interface SpinnerStore {
    isLoading: boolean;
}

// Define store key
export const spinnerStoreKey: InjectionKey<Store<SpinnerStore>> = Symbol();

// Define store
export const spinnerStore: Store<SpinnerStore> = createStore<SpinnerStore>({
    state: {
        isLoading: false,
    },
    mutations: {
        setIsLoading(state: SpinnerStore, isLoading: boolean): void {
            state.isLoading = isLoading;
        },
    },
});
