import { jwtDecode, JwtPayload } from 'jwt-decode';
import { loginKeycloak, refreshTokenKeycloak } from '@/api/keycloak/keycloak';
import router, { routeConfig } from '@/router';
import { userStore } from '@/store/user';
import userApi from '@/api/users';
import salonApi from '@/api/salons';
import { defaultManager } from '@/model/user';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { apiSettings, checkErrorStatus, createErrorMessage, ErrorMessage } from '@/api/global';
import { axiosAPI } from '@/axiosInstance';
import { spinnerStore } from '@/store/spinner';
import { AxiosError } from 'axios';
import { event } from 'vue-gtag';
import { useCookies } from 'vue3-cookies';
import configApi from '@/api/config';

/**
 * Login user
 *
 * @param {string} email - Email of user
 * @param {string} password - Password of user
 * @param {MessageApiInjection} message - Message API
 */
const login = async (email: string, password: string, message: MessageApiInjection) => {
    const body: URLSearchParams = new URLSearchParams({
        client_id: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
        username: email,
        password: password,
        grant_type: 'password',
    });
    try {
        const loginResponse = await loginKeycloak(body);
        spinnerStore.commit('setIsLoading', true);
        const roles = isManager(loginResponse.data);
        event('login', {
            method: 'Backoffice',
        });
        saveToken(loginResponse.data);
        // Pas obligé | On peut le faire via le token
        localStorage.setItem('zestee-roles', roles.toString());
        await saveInformations();
        await router.push({ name: routeConfig.ROUTE_HOME.name });
        spinnerStore.commit('setIsLoading', false);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorAxios = error as AxiosError;
            if (errorAxios.response?.status === 400) {
                await verifyRequiredAction(email, message);
            }
            await checkErrorStatus(
                errorAxios,
                message,
                new Map<number, ErrorMessage>([
                    [
                        401,
                        {
                            title: "Erreur d'authentification",
                            content: "L'adresse email ou le mot de passe est incorrect",
                        },
                    ],
                ])
            );
        } else {
            createErrorMessage(message, (error as Error).message);
            spinnerStore.commit('setIsLoading', false);
            throw error;
        }
    }
};

/**
 * Verify user mail in required action
 *
 * @param {string} email - Email of user
 * @param {MessageApiInjection } message - Message API
 */
async function verifyRequiredAction(email: string, message: MessageApiInjection) {
    await userApi.getXsfr();

    const response = (
        await axiosAPI.get(`${apiSettings.endpoints.users}/verify-email`, {
            params: {
                email: email,
            },
        })
    ).data;

    if (response.includes('VERIFY_EMAIL')) {
        message.info(
            "Votre compte n'est pas encore validé. Un email de validation vous a été envoyé."
        );
    } else {
        message.info("Un problème est survenu, merci de contacter l'administrateur");
    }
    throw new Error();
}

/**
 * Refresh token
 */
const refreshToken = async (isNotXSRF: boolean) => {
    const refreshToken = localStorage.getItem('zestee-refresh-token');

    if (refreshToken) {
        const body = new URLSearchParams({
            client_id: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        });

        try {
            const refreshResult = await refreshTokenKeycloak(body);
            saveToken(refreshResult.data);
            await saveInformations();
        } catch (error) {
            spinnerStore.commit('setIsLoading', true);
            await logout(true, true);
        }
    } else {
        spinnerStore.commit('setIsLoading', true);
        await logout(false, isNotXSRF);
        // Handle the case where there's no refresh token more gracefully
    }
};

/**
 * Logout user
 */
const logout = async function (redirect = true, isNotXSRF: boolean) {
    localStorage.clear();
    userStore.state.isAuthenticated = false;
    userStore.state.userInfo = defaultManager(false);
    userStore.state.ownedSalons = [];
    axiosAPI.defaults.headers.common['Authorization'] = undefined;
    if (isNotXSRF) {
        axiosAPI.defaults.headers.common['X-XSRF-TOKEN'] = undefined;
        axiosAPI.defaults.headers.common['Cookie'] = undefined;
        useCookies().cookies.remove('XSRF-TOKEN');
    }
    if (redirect) {
        await router.push({ name: routeConfig.ROUTE_LOGIN.name });
    }
    spinnerStore.commit('setIsLoading', false);
};

/**
 * Save token in local storage and user store
 * @param response - Response from keycloak
 */
function saveToken(response: any) {
    localStorage.setItem('zestee-token', response.access_token);
    localStorage.setItem('zestee-refresh-token', response.refresh_token);
}

/**
 * Save user information in local storage and user store
 */
export const saveInformations = async () => {
    // Adding keycloak token to vuexstorage
    userStore.commit('setRoles', localStorage.getItem('zestee-roles'));

    // Refresh local storage information
    // Can't use message there because n-message is not initialized yet
    localStorage.setItem('zestee-salons', JSON.stringify(await salonApi.getOwnedSalons()));
    localStorage.setItem('zestee-userinfo', JSON.stringify(await userApi.getUserInfo()));

    userStore.state.isAuthenticated = true;
    // Adding extra user info
    userStore.commit('setOwnedSalons', JSON.parse(localStorage.getItem('zestee-salons') as string));
    userStore.commit('setUserInfo', JSON.parse(localStorage.getItem('zestee-userinfo') as string));

    userStore.commit(
        'setPublicity',
        await configApi.getConfiguration('VUEJS').then((config) => config.config.reviveAdServer)
    );
};

const keycloakService = {
    login: login,
    logout: logout,
    refreshToken: refreshToken,
};

interface UserId extends JwtPayload {
    realm_access: {
        roles: string[];
    };
}

/**
 * Check if user is manager for access to the application
 * @param repJson - Response in json from keycloak login
 */
function isManager(repJson: any) {
    const jwtDecoded = jwtDecode<UserId>(repJson.access_token);
    const roles = jwtDecoded.realm_access.roles;
    if (!roles.includes('salon')) {
        throw new Error("Vous n'êtes pas manager");
    }
    return jwtDecoded.realm_access.roles;
}

export default keycloakService;
