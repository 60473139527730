import { Salon } from '@/model/salon';
import { FormItemRule } from 'naive-ui';
import { FormItemRuleValidator } from 'naive-ui/es/form/src/interface';

/**
 * Build rules with required rule
 * @param validator validators to add
 * @param errorMessage error message
 * @returns form item rule
 */
function requiredRule(
    validator: FormItemRuleValidator | undefined = undefined,
    errorMessage: string | undefined = undefined
): FormItemRule {
    let rules: FormItemRule = {
        required: true,
        trigger: 'blur',
        message: errorMessage ? errorMessage : 'Obligatoire',
    };
    if (validator) {
        rules = {
            ...rules,
            validator,
        };
    }
    return rules;
}

/**
 * Build rules with not required rule
 * @param validator validators to add
 * @param errorMessage error message
 * @returns form item rule
 */
function notRequiredRule(
    validator: FormItemRuleValidator | undefined = undefined,
    errorMessage: string | undefined = undefined
): FormItemRule {
    const rules: FormItemRule = {
        required: false,
        trigger: 'blur',
        message: errorMessage ? errorMessage : '',
    };

    // If a custom validator is provided, include it in the rules with modified behavior
    if (validator) {
        rules.validator = (rule, value, callback, source, options) => {
            // Call the provided validator if the value is not empty, otherwise return true
            if (value !== undefined && value !== null && value !== '') {
                return validator(rule, value, callback, source, options);
            }
            callback();
        };
    }

    return rules;
}

/**
 * Create the form rule to check if siren or siret is present
 * @param salon the salon
 * @returns the form rule
 */
function sirenOrSiret(salon: () => Salon): FormItemRule {
    return {
        trigger: 'blur',
        message: 'Merci de renseigner au moins un SIREN ou un SIRET',
        validator() {
            return (
                (salon().siren ?? '').trim().length > 0 || (salon().siret ?? '').trim().length > 0
            );
        },
    };
}

/**
 * Check if value is positive
 * @param rule the rule
 * @param value the input value
 * @returns true if positive false otherwise
 */
function isPositive(rule: FormItemRule, value: number | undefined): boolean {
    return value ? value >= 0 : false;
}

/**
 * Check if value list si not empty
 * @param rule the rule
 * @param value the input value
 * @returns true if not empty false otherwise
 */
function selectNotEmpty(rule: FormItemRule, value: Array<string>): boolean {
    return value ? value.filter((element) => element).length !== 0 : false;
}

/**
 * Check if the given value is correctly formatted mail address
 * @param rule the rule
 * @param value the input value
 * @returns true if the value is a correct address mail
 */
function isCorrectMail(rule: FormItemRule, value: string | undefined): boolean {
    if (!value) {
        return false;
    }
    const emailRegExp = new RegExp(
        '[0-9a-zA-Z]+([\\._-][0-9a-zA-Z]+)*@[0-9a-zA-Z]+([\\._-][0-9a-zA-Z]+)*\\.[a-z]{2,}'
    );
    const match = value.match(emailRegExp);
    return match != null && match?.length > 0 && match[0] === value;
}

/**
 * Check if the given phone number is valid
 * @param rule the form rule
 * @param value the input value
 * @returns true if the phone number is valid false otherwise
 */
function isInternationalPhoneNumber(rule: FormItemRule, value: string | undefined): boolean {
    if (!value) {
        return false;
    }
    const phoneRegExp = new RegExp('^\\+(?:[1-9] ?){1}(?:[0-9] ?){5,14}[0-9]$');
    const match = value.match(phoneRegExp);
    return match != null && match.length > 0 && match[0] === value;
}

function isBirthdateValid(rule: FormItemRule, value: string | undefined): boolean {
    if (!value) return false;

    const year = Number(value.substring(0, 4));
    const month = Number(value.substring(4, 6)) - 1;
    const days = Number(value.substring(6, 8));

    const dateValue = new Date(year, month, days);
    const dateActual = new Date();

    return dateActual > dateValue;
}

function isDateAfterOrEqualNow(rule: FormItemRule, value: string | undefined): boolean {
    if (!value) return false;
    const year = Number(value.substring(0, 4));
    const month = Number(value.substring(4, 6)) - 1;
    const day = Number(value.substring(6, 8));

    const dateValue = new Date(year, month, day);
    const currentDate = new Date();
    const realCurrentDate = new Date( //to get timestamp at 00:00:00 like dateValue
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
    );
    return realCurrentDate <= dateValue;
}

function isCorrectPatternPassword(rule: FormItemRule, value: string) {
    const minLength = 8;
    const maxLength = 20;

    if (value.length === 0) {
        return false;
    }

    const regex = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[A-Z]).{8,20}$/;

    if (!regex.test(value)) {
        if (value.length < minLength) {
            return new Error('Le mot de passe doit contenir au moins 8 caractères');
        } else if (value.length > maxLength) {
            return new Error('Le mot de passe doit contenir au plus 20 caractères');
        } else if (!/\d/.test(value)) {
            return new Error('Le mot de passe doit contenir au moins un chiffre');
        } else if (!/[a-z]/.test(value)) {
            return new Error('Le mot de passe doit contenir au moins une minuscule');
        } else if (!/[A-Z]/.test(value)) {
            return new Error('Le mot de passe doit contenir au moins une majuscule');
        } else if (!/[^a-zA-Z\d]/.test(value)) {
            return new Error('Le mot de passe doit contenir au moins un caractère spécial');
        } else if (/\s/.test(value)) {
            return new Error("Le mot de passe ne doit pas contenir d'espace");
        }
    }

    return true;
}

const Rules = {
    requiredRule,
    sirenOrSiret,
    validators: {
        isPositive,
        selectNotEmpty,
        isCorrectMail,
        isInternationalPhoneNumber,
        isBirthdateValid,
        isDateAfterOrEqualNow,
        isCorrectPatternPassword,
    },
    notRequiredRule,
};

export default Rules;
