import { axiosKeycloak } from '@/axiosInstance';

export const loginKeycloak = (body: URLSearchParams) => {
    return axiosKeycloak.post('/protocol/openid-connect/token', body.toString(), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const refreshTokenKeycloak = (body: URLSearchParams) => {
    return axiosKeycloak.post('/protocol/openid-connect/token', body.toString(), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};
