import { Address } from '@/model/generic';

interface RegisterModel {
    lastName: string;
    firstName: string;
    socialReason: string;
    email: string;
    password: string;
    passwordConfirm: string;
    phone: string;
    acceptCgu: boolean;
    isManager: boolean;
    address: Address;
}

function defaultRegisterTemplate() {
    return {
        lastName: '',
        firstName: '',
        socialReason: '',
        email: '',
        password: '',
        passwordConfirm: '',
        phone: '',
        acceptCgu: false,
        isManager: true,
        address: {
            zipCode: '',
            country: '',
        },
    };
}

interface LoginModel {
    email: string;
    password: string;
}

function defaultLoginTemplate(): LoginModel {
    return {
        email: '',
        password: '',
    };
}

export { LoginModel, RegisterModel, defaultRegisterTemplate, defaultLoginTemplate };
