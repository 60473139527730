import { PageSettings, SortableFilterState } from '@/model/generic';
import { PaginationProps } from 'naive-ui';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';

/**
 * Get page settings from query parameters. If not set, set page query paramerts with default values
 * @param router router
 * @param route route
 * @param optionalFilters optional additional query parameters
 * @returns page settings
 */
function pageSettingsFromQueryParameters(
    router: Router,
    route: RouteLocationNormalizedLoaded,
    optionalQueryParameters: object = {}
): PageSettings {
    if (route.query.pageNumber === undefined || route.query.pageSize === undefined) {
        return applyDefaultPageSettings(router, optionalQueryParameters);
    }

    return {
        pageNumber: Number(route.query.pageNumber),
        pageSize: Number(route.query.pageSize),
    };
}

/**
 * Apply default page settings to route and return them
 * @param router router to update
 * @param optionalQueryParameters optional query parameters to add
 * @return page settings
 */
function applyDefaultPageSettings(
    router: Router,
    optionalQueryParameters: SortableFilterState = {}
): PageSettings {
    // Add query parameters
    router.replace({
        query: {
            ...optionalQueryParameters,
            // FIXME can't pass default page settings to query due to type
            pageNumber: process.env.VUE_APP_DEFAULT_PAGE,
            pageSize: process.env.VUE_APP_DEFAULT_PAGE_SIZE,
        },
    });

    return {
        pageNumber: process.env.VUE_APP_DEFAULT_PAGE,
        pageSize: process.env.VUE_APP_DEFAULT_PAGE_SIZE,
    };
}

/**
 * Convert a naive ui pagination to an API pagination
 * @param pagination naive ui pagination
 * @returns API pagination
 */
function toPageSettings(pagination: PaginationProps): PageSettings {
    const page: number = pagination.page ? pagination.page - 1 : process.env.VUE_APP_DEFAULT_PAGE; // -1 since API starts at 0
    const pageSize: number = pagination.pageSize
        ? pagination.pageSize
        : process.env.VUE_APP_DEFAULT_PAGE_SIZE;

    return {
        pageNumber: page,
        pageSize: pageSize,
    };
}

const PageUtils = {
    applyDefaultPageSettings,
    pageSettingsFromQueryParameters,
    toPageSettings,
};

export default PageUtils;
