import App from '@/App.vue';
import router from '@/router';
import { userStore, userStoreKey } from '@/store/user';
import naive from 'naive-ui';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import i18n from './i18n/i18n';

createApp(App)
    .use(i18n.i18n)
    .use(naive)
    .use(userStore, userStoreKey)
    .use(router)
    .use(
        VueGtag,
        {
            config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
        },
        router
    )
    .use(VueTelInput as never)
    .mount('#app');
