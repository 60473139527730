import { apiSettings, checkErrorStatus, checkResponseStatus, ErrorMessage } from '@/api/global';
import {
    Application,
    ApplicationFilters,
    ApplicationStatus,
    JobApplications,
} from '@/model/application';
import { PageableResponse, PageSettings } from '@/model/generic';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { axiosAPI } from '@/axiosInstance';
import Utils from '@/utils/utils';
import { AxiosError } from 'axios';

/**
 * Get an application page
 *
 * @param {PageSettings} pageSettings - Page settings
 * @param {ApplicationFilters} filters - Filters
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<PageableResponse<Application>>} - Application page
 */
async function getApplications(
    pageSettings: PageSettings,
    filters: ApplicationFilters = {},
    message: MessageApiInjection
): Promise<PageableResponse<Application>> {
    try {
        const response = await axiosAPI.get(apiSettings.endpoints.applications, {
            responseType: 'json',
            params: {
                size: pageSettings.pageSize,
                page: pageSettings.pageNumber,
                ...filters,
            }, // Include filters as params
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return Utils.defaultPageable();
    }
}

/**
 * Fetch the salon applications
 *
 * @param {string} salonId - The salon ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<JobApplications>>} - The salon applications with counts
 */
async function getSalonApplications(
    salonId: string,
    message: MessageApiInjection
): Promise<Array<JobApplications>> {
    try {
        const response = await axiosAPI.get(
            `${apiSettings.endpoints.applications}/salon/${salonId}`,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        await checkErrorStatus(error as AxiosError, message);
        return [];
    }
}

/**
 * Set the status of an application
 *
 * @param {string} applicationId - The application ID
 * @param {ApplicationStatus} status - The new application status
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Application>}  The updated application
 */
async function setStatus(
    applicationId: string,
    status: ApplicationStatus,
    message: MessageApiInjection
): Promise<Application> {
    try {
        const response = await axiosAPI.patch(
            `${apiSettings.endpoints.applications}/${applicationId}/status/${status}`,
            null, // No request body for PATCH request
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(
            response,
            200,
            message,
            new Map<number, ErrorMessage>([
                [
                    400,
                    {
                        title: 'Erreur de statut',
                        content: 'Cette candidature a déjà ce statut',
                    },
                ],
            ])
        );
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    400,
                    {
                        title: 'Erreur de statut',
                        content: 'Cette candidature a déjà ce statut',
                    },
                ],
            ])
        );
    }
}

const applicationApi = {
    getApplications,
    getSalonApplications,
    setStatus,
};

export default applicationApi;
