import { apiSettings, checkErrorStatus, checkResponseStatus } from '@/api/global';
import { axiosAPI } from '@/axiosInstance';
import { ResumeStock } from '@/model/product';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { AxiosError } from 'axios';

/**
 * Fetch the logged-in user product to get his available stock
 *
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<ResumeStock>} - The logged-in user product
 */
async function getResumeStock(message: MessageApiInjection): Promise<ResumeStock> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.stock}/resumes`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

const stockApi = {
    getResumeStock,
};

export default stockApi;
