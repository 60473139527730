import { apiSettings, checkErrorStatus, checkResponseStatus } from '@/api/global';
import { axiosAPI } from '@/axiosInstance';
import { PageableResponse, PageSettings } from '@/model/generic';
import { Job, JobFilters } from '@/model/job';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import Utils from '@/utils/utils';
import { AxiosError } from 'axios';

/**
 * Get a job page
 *
 * @param {PageSettings} pageSettings - Page settings
 * @param {JobFilters} filters - Filters
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<PageableResponse<Job>>} - Job page
 */
async function getJobs(
    pageSettings: PageSettings,
    filters: JobFilters = {},
    message: MessageApiInjection
): Promise<PageableResponse<Job>> {
    try {
        const response = await axiosAPI.get(apiSettings.endpoints.jobs, {
            responseType: 'json',
            params: {
                size: pageSettings.pageSize,
                page: pageSettings.pageNumber,
                ...filters,
            }, // Include filters as params
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return Utils.defaultPageable();
    }
}

/**
 * Update a job
 *
 * @param {Job} job - Job to update
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Job>} - Updated job
 */
async function updateJob(job: Job, message: MessageApiInjection): Promise<Job> {
    try {
        const response = await axiosAPI.put(`${apiSettings.endpoints.jobs}/${job.id}`, job, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Delete a user
 *
 * @param {string | undefined} id - User ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<void>} - Empty promise
 */
async function remove(id: string | undefined, message: MessageApiInjection): Promise<void> {
    try {
        const response = await axiosAPI.delete(`${apiSettings.endpoints.jobs}/${id}`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 204, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Get a job by ID
 *
 * @param {string} id - Job ID
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Job>} - Job
 */
async function getById(id: string, message: MessageApiInjection): Promise<Job> {
    try {
        const response = await axiosAPI.get(`${apiSettings.endpoints.jobs}/${id}`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Create a new job
 *
 * @param {Job} job - Job
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Job>} - Created job
 */
async function createJob(job: Job, message: MessageApiInjection): Promise<Job> {
    try {
        const response = await axiosAPI.post(
            `${apiSettings.endpoints.salons}/${job.salon.id}/jobs`,
            job,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 201, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

const jobApi = {
    getJobs,
    remove,
    getById,
    createJob,
    updateJob,
};

export default jobApi;
