import { createI18n } from 'vue-i18n';
import messagesDe from './de/messages';
import messagesEn from './en/messages';
import messagesEs from './es/messages';
import messagesFr from './fr/messages';

const messages = {
    de: {
        message: messagesDe,
    },
    en: {
        message: messagesEn,
    },
    es: {
        message: messagesEs,
    },
    fr: {
        message: messagesFr,
    },
};

// Create VueI18n instance with options
const i18n = createI18n({
    locale: 'fr', // set locale
    fallbackLocale: 'fr',
    messages, // set locale messages
});

export default { i18n, messages };
