import { Address, Contract, HairdresserLevel } from '@/model/generic';
import { Salon, SalonCharacteristics, SalonSize, SalonType } from '@/model/salon';
import Utils from '@/utils/utils';

export const RequiredExperience = {
    ONE_YEAR: Utils.getLocalizedMessages().message.oneYear,
    TWO_YEARS: Utils.getLocalizedMessages().message.twoYears,
    THREE_YEARS: Utils.getLocalizedMessages().message.threeYears,
    FOUR_YEARS: Utils.getLocalizedMessages().message.fourYears,
    FIVE_YEARS: Utils.getLocalizedMessages().message.fiveYears,
    MORE: Utils.getLocalizedMessages().message.moreThanFiveYears,
};

export const JobStatus = {
    PUBLISHED: Utils.getLocalizedMessages().message.published,
    HIDDEN: Utils.getLocalizedMessages().message.hidden,
};

export type JobStatusKeys = keyof typeof JobStatus;

export const ContractModality = {
    FULL: Utils.getLocalizedMessages().message.fullTime,
    PARTIAL: Utils.getLocalizedMessages().message.partTime,
};

export const MissionDurationType = {
    MONTH: Utils.getLocalizedMessages().message.month,
    YEAR: Utils.getLocalizedMessages().message.year,
};

export interface Job {
    id?: string;
    salon: SalonJob;
    name?: string;
    startDate?: number;
    degreeEducationLevel?: string;
    requiredHairdresserLevel?: typeof HairdresserLevel;
    requiredExperience: Array<typeof RequiredExperience>;
    missionDuration?: number;
    missionDurationType?: typeof MissionDurationType;
    description?: string;
    grossSalary?: number;
    contractType?: typeof Contract;
    contractModality?: typeof ContractModality;
    status: JobStatusKeys;
    createdDate?: number;
    image?: string;
}

export interface SalonJob {
    id: string;
    type: typeof SalonType;
    name: string;
    characteristics: typeof SalonCharacteristics;
    activeSubscription: boolean;
    size: typeof SalonSize;
    address: Address;
}

export interface JobFilters {
    name?: string;
    salonName?: string;
    city?: string;
    zipCode?: string;
    sort?: string;
}

/**
 * Return a default job
 * @param salon the salon
 * @returns a job with default values
 */
export function defaultJob(salon: Salon): Job {
    return {
        requiredExperience: [],
        salon: salontoSalonJob(salon),
        status: 'PUBLISHED',
    };
}

/**
 * Map a salon to a Job salon
 * @param salon the salon
 * @returns a JobSalon
 */
function salontoSalonJob(salon: Salon): SalonJob {
    return {
        id: salon.id,
        type: salon.type,
        name: salon.name,
        characteristics: salon.characteristics,
        activeSubscription: !Utils.isDateBeforeToday(salon.subscription?.expiracyDate ?? 0),
        size: salon.size,
        address: salon.address,
    } as SalonJob;
}
