import Utils from '@/utils/utils';

export interface Invoice {
    id: number;
    reference: string;
    totalPrice: number;
    status: ApiInvoiceStatus;
    date: number;
    salonId: string;
    salonName: string;
    thirdPartyName: string;
}

export interface InvoiceFilters {
    reference?: string;
    name?: string;
    thirdPartyName?: string;
    city?: string;
    status?: string;
    sort?: string;
}

export const InvoiceStatus = {
    CLOSED: Utils.getLocalizedMessages().message.paid,
    VALIDATED: Utils.getLocalizedMessages().message.toPay,
};

export type ApiInvoiceStatus = 'CLOSED' | 'VALIDATED';
