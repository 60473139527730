import { apiSettings, checkResponseStatus } from '@/api/global';
import { AxiosResponse } from 'axios';
import { axiosAPI } from '@/axiosInstance';
import {
    Configuration,
    ConfigurationType,
    Dashboards,
    ReviveScreenName,
} from '@/model/configuration';

async function getConfiguration(type: ConfigurationType): Promise<Configuration> {
    const response: AxiosResponse<Configuration> = await axiosAPI.get(
        `${apiSettings.endpoints.config}/${type}`
    );
    return checkResponseStatus(response, 200);
}

async function getPublicityByName(type: ConfigurationType, vue: ReviveScreenName): Promise<number> {
    const response: AxiosResponse<Configuration> = await axiosAPI.get(
        `${apiSettings.endpoints.config}/${type}`
    );
    if (response.data.config.reviveAdServer === undefined) {
        throw new Error();
    }
    return response.data.config.reviveAdServer[vue] as number;
}

async function getDashboards(): Promise<{
    kibana: Dashboards | undefined;
    grafana: Dashboards | undefined;
}> {
    try {
        const response: AxiosResponse<Configuration> = await axiosAPI.get<Configuration>(
            `${apiSettings.endpoints.config}/vuejs`
        );
        return {
            kibana: response.data.config.kibana,
            grafana: response.data.config.grafana,
        };
    } catch (error) {
        console.error('Error fetching dashboards:', error);
        throw error; // Rethrow the error to handle it elsewhere
    }
}

const configApi = {
    getConfiguration,
    getPublicityByName,
    getDashboards,
};

export default configApi;
