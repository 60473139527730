/**
 * Check if a string trimmed value is empty or null or undefined
 * @param string the value to examinate
 * @return true if the given value meet one of the above requirement
 */
function isEmpty(string: string | undefined | null): boolean {
    return string === undefined || string === null || string.trim().length === 0;
}

function capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const StringUtils = {
    isEmpty,
    capitalize,
};

export default StringUtils;
