import { apiSettings, checkErrorStatus, checkResponseStatus, ErrorMessage } from '@/api/global';
import { axiosAPI } from '@/axiosInstance';
import { PageSettings, PageableResponse } from '@/model/generic';
import { Invoice, InvoiceFilters } from '@/model/invoice';
import Utils from '@/utils/utils';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { AxiosError } from 'axios';
import { event } from 'vue-gtag';

/**
 * Get invoices
 *
 * @param {PageSettings} page - Page parameters
 * @param {InvoiceFilters} filters - Page filters
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<PageableResponse<Invoice>>} - Invoices page
 */
async function getInvoices(
    page: PageSettings,
    filters: InvoiceFilters,
    message: MessageApiInjection
): Promise<PageableResponse<Invoice>> {
    try {
        const response = await axiosAPI.get(apiSettings.endpoints.invoices, {
            responseType: 'json',
            params: {
                size: page.pageSize,
                page: page.pageNumber,
                ...filters,
            },
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return Utils.defaultPageable();
    }
}

/**
 * Get logged in user invoices
 *
 * @param {PageSettings} page - Page settings
 * @param {InvoiceFilters} filters - Filters
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<PageableResponse<Invoice>>} - User invoices
 */
async function getMyInvoices(
    page: PageSettings,
    filters: InvoiceFilters,
    message: MessageApiInjection
): Promise<PageableResponse<Invoice>> {
    try {
        const response = await axiosAPI.get(`${apiSettings.endpoints.invoices}/me`, {
            responseType: 'json',
            params: {
                size: page.pageSize,
                page: page.pageNumber,
                ...filters,
            }, // Include filters as params
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    404,
                    {
                        title: 'Résultats introuvables',
                        content:
                            "Soit aucune facture n'est enregistrée à votre nom, soit la ressource demandée est inexistante sur le serveur.",
                    },
                ],
            ])
        );
        return Utils.defaultPageable();
    }
}

/**
 * Download the pdf invoice
 *
 * @param {Invoice} invoice - The invoice object
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<void>} - A promise that resolves when the invoice is downloaded
 */
async function downloadInvoice(invoice: Invoice, message?: MessageApiInjection): Promise<void> {
    try {
        const response = await axiosAPI.get(`${apiSettings.endpoints.invoices}/${invoice.id}`, {
            responseType: 'blob',
        });

        if (response.status !== 200) {
            throw response.status;
        }

        event('file_download', {
            file_extension: 'pdf',
            file_name: 'Facture',
        });

        Utils.downloadFile(await response.data, `${invoice.reference}.pdf`);
    } catch (error) {
        if (message) {
            return checkErrorStatus(error as AxiosError, message);
        }
        throw error;
    }
}

const invoiceApi = {
    getInvoices,
    downloadInvoice,
    getMyInvoices,
};

export default invoiceApi;
