import { userStore } from '@/store/user';
import Guards from '@/utils/guards';
import Utils from '@/utils/utils';
import ApplicationView from '@/views/admin/ApplicationView.vue';
import InvoiceViewVue from '@/views/admin/InvoiceView.vue';
import JobView from '@/views/admin/JobView.vue';
import SalonView from '@/views/admin/SalonView.vue';
import UserView from '@/views/admin/UserView.vue';
import JobCreateView from '@/views/create/JobCreateView.vue';
import ManagerCreateView from '@/views/create/ManagerCreateView.vue';
import HairdresserCreateView from '@/views/create/HairdresserCreateView.vue';
import SalonCreateView from '@/views/create/SalonCreateView.vue';
import HairdresserDetailsView from '@/views/HairdresserDetailsView.vue';
import ManagerDetailsView from '@/views/ManagerDetailsView.vue';
import HomeView from '@/views/HomeView.vue';
import ManagedSalonsDetails from '@/views/ManagedSalonsDetails.vue';
import MyProfileView from '@/views/MyProfileView.vue';
import OrderValidationView from '@/views/OrderValidationView.vue';
import ProductsView from '@/views/ProductsView.vue';
import RevealedResume from '@/views/RevealedResumesView.vue';
import SalonDetailsView from '@/views/SalonDetailsView.vue';
import SearchEmployeesView from '@/views/SearchEmployeesView.vue';
import ThanksView from '@/views/ThanksView.vue';
import PageNotFound from '@/views/error/PageNotFound.vue';
import LoginPage from '@/views/keycloak/LoginPage.vue';
import RegisterPage from '@/views/keycloak/RegisterPage.vue';
import {
    Cube as CubeOutlineIcon,
    Document as InvoiceIcon,
    LogOut as LogOutIcon,
    People as UserIcon,
    Search as ProspectSearchIcon,
    StatsChart,
} from '@vicons/ionicons5';
import { MenuOption } from 'naive-ui';
import { h, VNodeChild } from 'vue';
import {
    createRouter,
    createWebHashHistory,
    LocationQueryRaw,
    RouteLocationNormalized,
    RouteRecordName,
    RouteRecordRaw,
    RouterLink,
} from 'vue-router';
import PasswordModifyView from '@/views/keycloak/PasswordModifyView.vue';
import PasswordRequestView from '@/views/keycloak/PasswordRequestView.vue';
import keycloakService from '@/security/keycloak-service';

const ROUTE_HOME: RouteRecordRaw = {
    path: '/',
    name: 'home',
    component: HomeView,
};

const ROUTE_THANKS: RouteRecordRaw = {
    path: '/thanks',
    name: 'thanks',
    component: ThanksView,
};

const ROUTE_MANAGED_SALONS_DETAILS: RouteRecordRaw = {
    path: '/manager/salons/:id?/details',
    name: 'managed-salons-details',
    component: ManagedSalonsDetails,
};

const ROUTE_MANAGED_SALONS_APPLICANTS: RouteRecordRaw = {
    path: '/manager/salons/:id?/applicants',
    name: 'managed-salons-applicants',
    component: ManagedSalonsDetails,
};

const ROUTE_MANAGED_SALONS_OWNERS: RouteRecordRaw = {
    path: '/manager/salons/:id/owners',
    name: 'managed-salons-owners',
    component: ManagedSalonsDetails,
};

const ROUTE_MANAGED_SALONS_SUBSCRIPTION: RouteRecordRaw = {
    path: '/manager/salons/:id/subscription',
    name: 'managed-salons-subscription',
    component: ManagedSalonsDetails,
};

const ROUTE_MANAGED_SALONS_JOBS: RouteRecordRaw = {
    path: '/manager/salons/:id?/jobs',
    name: 'managed-salons-jobs',
    component: ManagedSalonsDetails,
};

const ROUTE_MANAGED_SALONS_PICTURES: RouteRecordRaw = {
    path: '/manager/salons/:id/pictures',
    name: 'managed-salons-pictures',
    component: ManagedSalonsDetails,
};

const ROUTE_MANAGED_SALONS_VIDEO: RouteRecordRaw = {
    path: '/manager/salons/:id/video',
    name: 'managed-salons-video',
    component: ManagedSalonsDetails,
};

const ROUTE_ADMIN_SALON_OWNERS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons/:id/owners',
    name: 'admin-salon-managers',
    component: SalonDetailsView,
};

const ROUTE_ADMIN_SALONS_JOBS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons/:id/jobs',
    name: 'admin-salons-jobs',
    component: SalonDetailsView,
};

const ROUTE_ADMIN_SALONS_APPLICANTS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons/:id/applicants',
    name: 'admin-salons-applicants',
    component: SalonDetailsView,
};

const ROUTE_ADMIN_SALONS_PICTURES: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons/:id/pictures',
    name: 'admin-salons-pictures',
    component: SalonDetailsView,
    props: {
        detailsRoute: 'salons-details',
        ownersRoute: ROUTE_ADMIN_SALON_OWNERS.name,
        jobRoute: ROUTE_ADMIN_SALONS_JOBS.name,
    },
};

const ROUTE_ADMIN_SALONS_VIDEO: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons/:id/video',
    name: 'admin-salons-video',
    component: SalonDetailsView,
    props: {
        detailsRoute: 'salons-details',
        ownersRoute: ROUTE_ADMIN_SALON_OWNERS.name,
        jobRoute: ROUTE_ADMIN_SALONS_JOBS.name,
    },
};
const ROUTE_ADMIN_SALONS_SUBSCRIPTION: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons/:id/subscription',
    name: 'admin-salons-subscription',
    component: SalonDetailsView,
};

const ROUTE_ADMIN_SALONS_DETAILS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons/:id/details',
    name: 'admin-salons-details',
    component: SalonDetailsView,
    props: {
        detailsRoute: 'salons-details',
        ownersRoute: ROUTE_ADMIN_SALON_OWNERS.name,
        jobRoute: ROUTE_ADMIN_SALONS_JOBS.name,
        mediaRoute: ROUTE_ADMIN_SALONS_PICTURES.name,
    },
};

const ROUTE_SALONS_CREATE: RouteRecordRaw = {
    path: '/salons/create',
    name: 'salons-create',
    component: SalonCreateView,
};

const ROUTE_ADMIN_JOBS_CREATE: RouteRecordRaw = {
    path: '/administration/salons/:id/jobs/create',
    name: 'salons-jobs-create',
    component: JobCreateView,
};

const ROUTE_HAIRDRESSERS_DETAILS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/hairdressers/:id',
    name: 'hairdressers-details',
    component: HairdresserDetailsView,
};

const ROUTE_MANAGERS_DETAILS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/managers/:id',
    name: 'managers-details',
    component: ManagerDetailsView,
};

const ROUTE_CREATE_MANAGER: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/managers/create',
    name: 'manager-create',
    component: ManagerCreateView,
};

const ROUTE_CREATE_HAIRDRESSER: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/hairdressers/create',
    name: 'hairdresser-create',
    component: HairdresserCreateView,
};

const ROUTE_ADMIN_HAIRDRESSERS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/hairdressers',
    name: 'admin-hairdressers',
    component: UserView,
};

const ROUTE_ADMIN_MANAGERS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/managers',
    name: 'admin-managers',
    component: UserView,
};

const ROUTE_ADMIN_SALONS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/salons',
    name: 'admin-salons',
    component: SalonView,
};

const ROUTE_ADMIN_JOBS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/jobs',
    name: 'admin-jobs',
    component: JobView,
};

const ROUTE_ADMIN_APPLICATIONS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/applications',
    name: 'admin-applications',
    component: ApplicationView,
};

const ROUTE_ORDER_VALIDATION: RouteRecordRaw = {
    path: '/orders/:extRef/validate',
    name: 'order-validate',
    component: OrderValidationView,
};

const ROUTE_PRODUCT: RouteRecordRaw = {
    path: '/products',
    name: 'products',
    component: ProductsView,
};

const ROUTE_ADMIN_INVOICES: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/invoices',
    name: 'admin-invoices',
    component: InvoiceViewVue,
};

const ROUTE_MANAGER_INVOICES: RouteRecordRaw = {
    path: '/manager/invoices',
    name: 'manager-invoices',
    component: InvoiceViewVue,
};

const ROUTE_MY_PROFILE: RouteRecordRaw = {
    path: '/me',
    name: 'my-profile',
    component: MyProfileView,
};

const ROUTE_PROSPECTS: RouteRecordRaw = {
    path: '/manager/employees/search',
    name: 'manager-search-employees',
    component: SearchEmployeesView,
};

const ROUTE_REAVEALED_RESUMES: RouteRecordRaw = {
    path: '/manager/employees/revealed',
    name: 'manager-revealed-resumes',
    component: RevealedResume,
};

const ROUTE_LOGIN: RouteRecordRaw = {
    path: '/login',
    name: 'login',
    component: LoginPage,
};

const ROUTE_REGISTER: RouteRecordRaw = {
    path: '/register',
    name: 'register',
    component: RegisterPage,
};

const ROUTE_PASSWORD_RECOVERY: RouteRecordRaw = {
    path: '/forget-password',
    name: 'forget-password',
    component: PasswordRequestView,
};

const ROUTE_PASSWORD_UPDATE: RouteRecordRaw = {
    path: '/forget-password/:id',
    name: 'update-password',
    component: PasswordModifyView,
};

const ROUTE_ADMIN_STATS: RouteRecordRaw = {
    beforeEnter: () => Guards.adminOrRedirect(),
    path: '/administration/stats',
    name: 'admin-stats',
    component: () => import('@/views/admin/StatsView.vue'),
};

export function generateMenuOptions(
    currentMenu: RouteRecordName | null | undefined
): Array<MenuOption> {
    const userInfoString = localStorage.getItem('zestee-userinfo');
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    const isSubscribed = userInfo ? userInfo.isSubscribed : false;

    return [
        getRouteMenuOption(
            ROUTE_HOME.name,
            currentMenu,
            'Accueil',
            Utils.renderPicto('assets/images/icons/Picto_Home_noir.svg'),
            Utils.renderPicto('assets/images/icons/Picto_Home_blanc.svg')
        ),
        getSubMenuOption(
            'Administration',
            Utils.renderPicto('assets/images/icons/Picto_Administration_noir.svg'),
            [
                getRouteMenuOption(
                    ROUTE_ADMIN_HAIRDRESSERS.name,
                    currentMenu,
                    'Coiffeurs',

                    Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_couleurs.svg'),
                    Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_blanc.svg'),
                    true
                ),
                getRouteMenuOption(
                    ROUTE_ADMIN_MANAGERS.name,
                    currentMenu,
                    'Employeurs',

                    Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_couleurs.svg'),
                    Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_blanc.svg'),
                    true
                ),
                getRouteMenuOption(
                    ROUTE_ADMIN_SALONS.name,
                    currentMenu,
                    'Salons',
                    Utils.renderPicto('assets/images/icons/Picto_GererSalons_couleurs.svg'),
                    Utils.renderPicto('assets/images/icons/Picto_GererSalons_blanc.svg'),
                    true
                ),
                getRouteMenuOption(
                    ROUTE_ADMIN_JOBS.name,
                    currentMenu,
                    "Offres d'emploi",
                    Utils.renderPicto('assets/images/icons/Picto_OffresEmplois_couleurs.svg'),
                    Utils.renderPicto('assets/images/icons/Picto_OffresEmplois_blanc.svg'),
                    true
                ),
                getRouteMenuOption(
                    ROUTE_ADMIN_APPLICATIONS.name,
                    currentMenu,
                    'Candidatures',
                    Utils.renderPicto('assets/images/icons/Picto_Candidatures_couleurs.svg'),
                    Utils.renderPicto('assets/images/icons/Picto_Candidatures_blanc.svg'),
                    true,
                    false,
                    { sort: 'createdDate,desc' }
                ),
                getRouteMenuOption(
                    ROUTE_ADMIN_INVOICES.name,
                    currentMenu,
                    'Factures',
                    Utils.renderIcon(InvoiceIcon),
                    Utils.renderIcon(InvoiceIcon),
                    true,
                    false,
                    { sort: 'date,desc' }
                ),
            ],
            true
        ),
        getSubMenuOption(
            'Mes Salons',
            Utils.renderPicto('assets/images/icons/Picto_MesSalons_noir.svg'),
            [
                getRouteMenuOption(
                    ROUTE_MANAGED_SALONS_DETAILS.name,
                    currentMenu,
                    'Gérer mes salons',
                    Utils.renderPicto('assets/images/icons/Picto_GererSalons_couleurs.svg'),
                    Utils.renderPicto('assets/images/icons/Picto_GererSalons_blanc.svg'),
                    false
                ),
                getRouteMenuOption(
                    ROUTE_SALONS_CREATE.name,
                    currentMenu,
                    'Créer un salon',
                    Utils.renderPicto('assets/images/icons/Picto_CreerSalons_couleurs.svg'),
                    Utils.renderPicto('assets/images/icons/Picto_CreerSalons_blanc.svg'),
                    false
                ),
            ],
            false,
            true
        ),
        getRouteMenuOption(
            ROUTE_MANAGED_SALONS_APPLICANTS.name,
            currentMenu,
            'Mes candidatures',
            Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_couleurs.svg'),
            Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_blanc.svg'),
            false,
            true
        ),
        getSubMenuOption(
            'CVthèque',
            Utils.renderIcon(UserIcon),
            [
                getRouteMenuOption(
                    ROUTE_PROSPECTS.name,
                    currentMenu,
                    "Recherche de CV's",
                    Utils.renderIcon(ProspectSearchIcon),
                    Utils.renderIcon(ProspectSearchIcon),
                    false,
                    true
                ),
                getRouteMenuOption(
                    ROUTE_REAVEALED_RESUMES.name,
                    currentMenu,
                    isSubscribed ? "Mes CV's" : "CV's achetés",
                    Utils.renderIcon(UserIcon),
                    Utils.renderIcon(UserIcon),
                    false,
                    true
                ),
            ],
            false,
            true
        ),
        getRouteMenuOption(
            ROUTE_PRODUCT.name,
            currentMenu,
            "Achat de CV's",
            Utils.renderIcon(CubeOutlineIcon),
            Utils.renderIcon(CubeOutlineIcon),
            false,
            true
        ),
        getRouteMenuOption(
            ROUTE_MANAGER_INVOICES.name,
            currentMenu,
            'Mes factures',
            Utils.renderIcon(InvoiceIcon),
            Utils.renderIcon(InvoiceIcon),
            false,
            true,
            { sort: 'date,desc' }
        ),

        getRouteMenuOption(
            ROUTE_ADMIN_STATS.name,
            currentMenu,
            'Statistiques',
            Utils.renderIcon(StatsChart),
            Utils.renderIcon(StatsChart),
            true,
            false
        ),
        getRouteMenuOption(
            ROUTE_MY_PROFILE.name,
            currentMenu,
            'Mon profil',
            Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_couleurs.svg'),
            Utils.renderPicto('assets/images/icons/Picto_Utilisateurs_blanc.svg')
        ),
        getMenuOption('logout', 'Se déconnecter', Utils.renderIcon(LogOutIcon)),
    ];
}

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        ROUTE_HOME,
        ROUTE_THANKS,
        ROUTE_HAIRDRESSERS_DETAILS,
        ROUTE_MANAGERS_DETAILS,
        ROUTE_ADMIN_SALONS_DETAILS,
        ROUTE_SALONS_CREATE,
        ROUTE_ADMIN_SALON_OWNERS,
        ROUTE_ADMIN_SALONS_SUBSCRIPTION,
        ROUTE_ADMIN_SALONS_JOBS,
        ROUTE_ADMIN_SALONS_APPLICANTS,
        ROUTE_ADMIN_SALONS_PICTURES,
        ROUTE_ADMIN_SALONS_VIDEO,
        ROUTE_ADMIN_JOBS_CREATE,
        ROUTE_ADMIN_HAIRDRESSERS,
        ROUTE_ADMIN_MANAGERS,
        ROUTE_ADMIN_SALONS,
        ROUTE_ADMIN_JOBS,
        ROUTE_ADMIN_APPLICATIONS,
        ROUTE_MANAGED_SALONS_DETAILS,
        ROUTE_MANAGED_SALONS_JOBS,
        ROUTE_MANAGED_SALONS_OWNERS,
        ROUTE_MANAGED_SALONS_PICTURES,
        ROUTE_MANAGED_SALONS_VIDEO,
        ROUTE_MANAGED_SALONS_SUBSCRIPTION,
        ROUTE_MANAGED_SALONS_APPLICANTS,
        ROUTE_ORDER_VALIDATION,
        ROUTE_PRODUCT,
        ROUTE_ADMIN_INVOICES,
        ROUTE_MANAGER_INVOICES,
        ROUTE_CREATE_MANAGER,
        ROUTE_CREATE_HAIRDRESSER,
        ROUTE_MY_PROFILE,
        ROUTE_PROSPECTS,
        ROUTE_REAVEALED_RESUMES,
        ROUTE_LOGIN,
        ROUTE_REGISTER,
        ROUTE_PASSWORD_RECOVERY,
        ROUTE_PASSWORD_UPDATE,
        ROUTE_ADMIN_STATS,
        { path: '/:pathMatch(.*)*', name: 'not-found', component: PageNotFound },
    ],
});

/**
 * Get naive UI menu options with route
 * @param routeName route name (identifier in router)
 * @param currentMenu the current menu
 * @param label menu label
 * @param icon  menu icon
 * @param onlyAdmin true if admin menu false otherwise
 * @param routeParams if the route contains parameter insert them here
 * @param menuKey menu key if not provided the route name is inserted
 * @returns menu options
 */
function getRouteMenuOption(
    routeName: RouteRecordName | undefined,
    currentMenu: RouteRecordName | null | undefined,
    label: string,
    icon: VNodeChild,
    activeIcon: VNodeChild,
    onlyAdmin = false,
    onlySalon = false,
    queryParam: LocationQueryRaw = {}
): MenuOption {
    const showItem: boolean = onlyAdmin
        ? userStore.getters.isAdmin
        : onlySalon
        ? !userStore.getters.isAdmin
        : true;
    return {
        key: routeName as string,
        label: () =>
            h(
                RouterLink,
                {
                    to: {
                        name: routeName,
                        query: queryParam,
                    },
                },
                { default: () => label }
            ),
        icon: () => (currentMenu === routeName ? activeIcon : icon),
        show: showItem,
    };
}

/**
 * Get naive UI simple menu options
 * @param key route key (identifier in router)
 * @param label menu label
 * @param icon  menu icon
 * @returns menu options
 */
function getMenuOption(key: string, label: string, icon: VNodeChild): MenuOption {
    return {
        key,
        label,
        icon: () => icon,
    };
}

/**
 * Get naive UI sub menu options
 * @param label menu label
 * @param icon  menu icon
 * @param children list of children menu options
 * @param onlyAdmin true if admin menu false otherwise
 * @param onlySalon true id salon menu false otherwise
 * @returns menu options
 */
function getSubMenuOption(
    label: string,
    icon: VNodeChild,
    children: Array<MenuOption>,
    onlyAdmin = false,
    onlySalon = false
): MenuOption {
    const showItem: boolean = onlyAdmin
        ? userStore.getters.isAdmin
        : onlySalon
        ? !userStore.getters.isAdmin
        : true;
    return {
        key: label.toLowerCase(),
        label,
        icon: () => icon,
        children,
        show: showItem,
    };
}

export function hasToRedirect(to: RouteLocationNormalized): boolean {
    return (
        to.name != ROUTE_LOGIN.name &&
        to.name != ROUTE_REGISTER.name &&
        to.name != ROUTE_PASSWORD_RECOVERY.name &&
        to.name != ROUTE_PASSWORD_UPDATE.name &&
        to.name != 'not-found'
    );
}

router.beforeEach(async (to: RouteLocationNormalized) => {
    const isNotPageKeycloak = hasToRedirect(to);

    if (!userStore.state.isAuthenticated && localStorage.getItem('zestee-refresh-token')) {
        await keycloakService.refreshToken(true);
        return;
    }

    if (!userStore.state.isAuthenticated && isNotPageKeycloak) {
        return { name: ROUTE_LOGIN.name };
    } else if (
        userStore.state.isAuthenticated &&
        (to.name == ROUTE_LOGIN.name ||
            to.name == ROUTE_REGISTER.name ||
            to.name == ROUTE_PASSWORD_RECOVERY.name ||
            to.name == ROUTE_PASSWORD_UPDATE.name)
    ) {
        return { name: ROUTE_HOME.name };
    }
});

export default router;
export const routeConfig = {
    ROUTE_HOME,
    ROUTE_HAIRDRESSERS_DETAILS,
    ROUTE_MANAGERS_DETAILS,
    ROUTE_SALONS_DETAILS: ROUTE_ADMIN_SALONS_DETAILS,
    ROUTE_SALONS_CREATE,
    ROUTE_SALON_OWNERS: ROUTE_ADMIN_SALON_OWNERS,
    ROUTE_SALONS_JOBS: ROUTE_ADMIN_SALONS_JOBS,
    ROUTE_ADMIN_SALONS_SUBSCRIPTION,
    ROUTE_JOBS_CREATE: ROUTE_ADMIN_JOBS_CREATE,
    ROUTE_ADMIN_SALONS_PICTURES,
    ROUTE_ADMIN_SALONS_VIDEO,
    ROUTE_ADMIN_HAIRDRESSERS,
    ROUTE_ADMIN_MANAGERS,
    ROUTE_ADMIN_SALONS,
    ROUTE_ADMIN_SALONS_APPLICANTS,
    ROUTE_ADMIN_JOBS,
    ROUTE_ADMIN_APPLICATIONS,
    ROUTE_MANAGED_SALONS_DETAILS,
    ROUTE_MANAGED_SALONS_OWNERS,
    ROUTE_MANAGED_SALONS_JOBS,
    ROUTE_MANAGED_SALONS_PICTURES: ROUTE_MANAGED_SALONS_PICTURES,
    ROUTE_MANAGED_SALONS_VIDEO,
    ROUTE_MANAGED_SALONS_SUBSCRIPTION,
    ROUTE_MANAGED_SALONS_APPLICANTS,
    ROUTE_ORDER_VALIDATION,
    ROUTE_PRODUCT,
    ROUTE_ADMIN_INVOICES,
    ROUTE_MANAGER_INVOICES,
    ROUTE_CREATE_MANAGER,
    ROUTE_CREATE_HAIRDRESSER,
    ROUTE_MY_PROFILE,
    ROUTE_PROSPECTS,
    ROUTE_REAVEALED_RESUMES,
    ROUTE_LOGIN,
    ROUTE_REGISTER,
    ROUTE_PASSWORD_RECOVERY,
    ROUTE_PASSWORD_UPDATE,
    ROUTE_ADMIN_STATS,
};
