import { Salon } from '@/model/salon';
import { defaultManager, Manager } from '@/model/user';
import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { RevivePublicity } from '@/model/configuration';

// User type
export interface UserStore {
    roles: Array<string>;
    userInfo: Manager;
    ownedSalons: Array<Salon>;
    isAuthenticated: boolean;
    publicity: RevivePublicity;
}

// Define store key
export const userStoreKey: InjectionKey<Store<UserStore>> = Symbol();

// Define store
export const userStore: Store<UserStore> = createStore<UserStore>({
    state: {
        roles: [],
        userInfo: defaultManager(false),
        ownedSalons: [],
        isAuthenticated: false,
        publicity: {},
    },
    getters: {
        isAdmin: (state: UserStore): boolean => state.roles.includes('admin'),
    },
    mutations: {
        setRoles(state: UserStore, roles: string): void {
            state.roles = roles ? roles.split(',') : [];
        },
        setUserInfo(state: UserStore, userInfo: Manager): void {
            state.userInfo = userInfo;
        },
        /**
         * Set the user owned salons
         * @param state the state
         * @param salons the salons
         */
        setOwnedSalons(state: UserStore, salons: Array<Salon>): void {
            state.ownedSalons = salons;
        },
        /**
         * add a salon to the user ownership
         * @param state the state
         * @param salon the salon
         */
        addOwnedSalon(state: UserStore, salon: Salon) {
            state.ownedSalons.push(salon);
        },
        /**
         * Remove a salon from the ownership
         * @param state the state
         * @param salonId the salon Id
         */
        removeOwnedSalon(state: UserStore, salonId: string) {
            state.ownedSalons.forEach((salon, index) => {
                if (salon.id === salonId) {
                    state.ownedSalons.splice(index, 1);
                }
            });
        },

        setPublicity(state: UserStore, publicity: RevivePublicity) {
            state.publicity = publicity;
        },
    },
    modules: {},
});

// Add wrapper to simply get user store without key
export function getUserStore(): Store<UserStore> {
    return baseUseStore(userStoreKey);
}
