export interface Order {
    id: string;
    date: number;
    tvaPrice: number;
    status: string;
    productLabel: string;
    productReference: string;
    productType: ProductType;
    renew: boolean;
    totalPrice: number;
    tvaPercentage: number;
    ownerId: string;
    quantity: number;
}

export type ProductType = 'service' | 'product';

export interface OrderCreateRequest {
    salonId: string;
    productId?: string;
    renew: boolean;
    paymentMailUserId?: string | undefined;
}

export interface ValidatedOrder {
    callback: string;
}

export function defaultPaymentOrder(salonId: string): OrderCreateRequest {
    return {
        salonId,
        renew: false,
    };
}
