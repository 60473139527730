import {
    apiSettings,
    checkErrorStatus,
    checkResponseStatus,
    createErrorMessage,
    ErrorMessage,
} from '@/api/global';
import { MediaType, PageableResponse, PageSettings } from '@/model/generic';
import { Job } from '@/model/job';
import { Salon, SalonFilters } from '@/model/salon';
import { Manager } from '@/model/user';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { axiosAPI } from '@/axiosInstance';
import Utils from '@/utils/utils';
import { spinnerStore } from '@/store/spinner';
import { AxiosError } from 'axios';
import { event } from 'vue-gtag';

/**
 * Get a salon page
 *
 * @param {PageSettings} pageSettings - Page settings
 * @param {SalonFilters} filters - Filters
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<PageableResponse<Salon>>} - Salon page
 */
async function getSalons(
    pageSettings: PageSettings,
    filters: SalonFilters = {},
    message: MessageApiInjection
): Promise<PageableResponse<Salon>> {
    try {
        const response = await axiosAPI.get(apiSettings.endpoints.salons, {
            responseType: 'json',
            params: {
                size: pageSettings.pageSize,
                page: pageSettings.pageNumber,
                ...filters,
            }, // Include filters as params
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return Utils.defaultPageable();
    }
}

/**
 * Upload a media for the given salon
 *
 * @param {Blob} blob - The file
 * @param {string} salonId - The salonId
 * @param {MediaType} mediaType - The media type
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<void>} - Promise that resolves when the upload is successful
 */
async function uploadMedia(
    blob: Blob,
    salonId: string,
    mediaType: MediaType,
    message: MessageApiInjection
): Promise<Salon> {
    try {
        const formData = new FormData();
        if (blob.size > process.env['VUE_APP_FILE_MAX_SIZE'] * 1024 ** 2) {
            throw 'Le fichier est trop volumineux';
        }

        formData.append('file', blob);

        const url = `${apiSettings.endpoints.salons}/${salonId}/media/${mediaType}`;
        const response = await axiosAPI.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        if (error instanceof AxiosError) {
            return checkErrorStatus(error as AxiosError, message);
        }

        createErrorMessage(message, (error as Error).message);
        throw error; // Rethrow the error if needed
    }
}

/**
 * Update subscription renew
 *
 * @param {string} salonId - The salon id
 * @param {boolean} renew - Whether to renew the subscription or cancel
 * @param {MessageApiInjection} message - Message API
 * @param {string[] | undefined} reasons - The reasons for the cancelation
 * @returns {Promise<Salon>} - Promise that resolves with the updated salon
 */
async function saveSubscription(
    salonId: string,
    renew: boolean,
    message: MessageApiInjection,
    reasons?: string[]
): Promise<Salon> {
    try {
        const path = renew ? 'subscription/renew' : 'subscription/cancel';
        const method = renew ? 'POST' : 'DELETE';
        const params = reasons && reasons.length > 0 ? { reasons: [...reasons] } : {};

        // Make the Axios request without explicit headers
        const response = await axiosAPI.request({
            method,
            url: `${apiSettings.endpoints.salons}/${salonId}/${path}`,
            params,
            responseType: 'json',
            paramsSerializer: {
                indexes: null,
            },
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Delete a media in the given salon id and media type that match file path if type is PORTFOLIO, all images if file path is null
 *
 * @param {string} salonId - The salon id
 * @param {MediaType} mediaType - The media type
 * @param {MessageApiInjection} message - Message API
 * @param {string | undefined} filePath - The file path to delete for salon picture, if absent delete all
 * @returns {Promise<Salon>} - Promise that resolves with the updated salon
 */
async function deleteMedia(
    salonId: string,
    mediaType: MediaType,
    message: MessageApiInjection,
    filePath?: string
): Promise<Salon> {
    try {
        // Construct the URL with or without filepath query parameter
        const url = `${apiSettings.endpoints.salons}/${salonId}/media/${mediaType}${
            filePath ? `?filepath=${filePath}` : ''
        }`;

        // Make the Axios request without explicit headers
        const response = await axiosAPI.delete(url, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Get salon by id
 *
 * @param {string} id - Salon id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Salon>} - Promise that resolves with the salon
 */
async function getSalonById(id: string, message: MessageApiInjection): Promise<Salon> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.salons}/${id}`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Create a new salon
 *
 * @param {Salon} salon - Salon data
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Salon>} - Promise that resolves with the created salon
 */
async function createSalon(salon: Salon, message: MessageApiInjection): Promise<Salon> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.post(apiSettings.endpoints.salons, salon, {
            responseType: 'json',
        });

        event('salon', {
            type: 'create',
        });

        return checkResponseStatus(response, 201, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Update a salon
 *
 * @param {Salon} salon - Updated salon data
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Salon>} - Promise that resolves with the updated salon
 */
async function updateSalon(salon: Salon, message: MessageApiInjection): Promise<Salon> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.put(`${apiSettings.endpoints.salons}/${salon.id}`, salon, {
            responseType: 'json',
        });

        event('salon', {
            type: 'update',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Delete a salon
 *
 * @param {string | undefined} id - Salon id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<void>} - Empty Promise
 */
async function remove(id: string | undefined, message: MessageApiInjection): Promise<void> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.delete(`${apiSettings.endpoints.salons}/${id}`, {
            responseType: 'json',
        });

        event('salon', {
            type: 'delete',
        });

        return checkResponseStatus(response, 204, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Get salon jobs
 *
 * @param {string} id - Salon id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Job>>} - Job list promise
 */
async function getJobs(id: string, message: MessageApiInjection): Promise<Array<Job>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.salons}/${id}/jobs`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        checkErrorStatus(error as AxiosError, message);
        return [];
    }
}

/**
 * Get the salon owners
 *
 * @param {string} salonId - The salon id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Manager>>} - The owners list
 */
async function getOwners(salonId: string, message: MessageApiInjection): Promise<Array<Manager>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.salons}/${salonId}/owners`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * Add a new owner to the salon
 *
 * @param {string} userMail - The new owner mail
 * @param {string} salonId - The salon id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Manager>} - The added owner
 */
async function addOwner(
    userMail: string,
    salonId: string,
    message: MessageApiInjection
): Promise<Manager> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.put(
            `${apiSettings.endpoints.salons}/${salonId}/owners/${encodeURIComponent(userMail)}`,
            null, // No request body for PUT request
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(
            response,
            200,
            message,
            new Map<number, ErrorMessage>([
                [
                    404,
                    {
                        title: 'Manager inconnu',
                        content:
                            "Cette adresse mail n'existe pas dans notre base, vérifiez votre saisie et réessayez",
                    },
                ],
            ])
        );
    } catch (error) {
        return checkErrorStatus(
            error as AxiosError,
            message,
            new Map<number, ErrorMessage>([
                [
                    404,
                    {
                        title: 'Manager inconnu',
                        content:
                            "Cette adresse mail n'existe pas dans notre base, vérifiez votre saisie et réessayez",
                    },
                ],
            ])
        );
    }
}

/**
 * Remove the owner from the given salon
 *
 * @param {string} salonId - The salon Id
 * @param {string} userId - The user Id
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<void>} - A promise that resolves when the owner has been removed
 */
async function removeOwner(
    salonId: string,
    userId: string,
    message: MessageApiInjection
): Promise<void> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.delete(
            `${apiSettings.endpoints.salons}/${salonId}/owners/${userId}`,
            {
                responseType: 'json',
            }
        );

        return checkResponseStatus(response, 204, message);
    } catch (error) {
        return checkErrorStatus(error as AxiosError, message);
    }
}

/**
 * The list of salons that match the filters and that the user owns
 *
 * @param {MessageApiInjection} message - Message API
 * @returns {Promise<Array<Salon>>} - The matching salons that the user owns
 */
async function getOwnedSalons(message?: MessageApiInjection): Promise<Array<Salon>> {
    try {
        // Make the Axios request without explicit headers
        const response = await axiosAPI.get(`${apiSettings.endpoints.managers}/salons`, {
            responseType: 'json',
        });

        return checkResponseStatus(response, 200, message);
    } catch (error) {
        if (!message) {
            spinnerStore.commit('setIsLoading', false);
            throw error;
        }
        checkErrorStatus(error as AxiosError, message);
        return [];
    }
}

const salonApi = {
    getSalons,
    getSalonById,
    createSalon,
    updateSalon,
    getOwners,
    addOwner,
    removeOwner,
    remove,
    getJobs,
    getOwnedSalons,
    uploadMedia,
    deleteMedia,
    saveSubscription,
};

export default salonApi;
