import { Address, Contract, HairdresserLevel, HairdresserlevelStrings } from '@/model/generic';
import Utils from '@/utils/utils';

export const GeographicAvailability = {
    YES: Utils.getLocalizedMessages().message.yes,
    NO: Utils.getLocalizedMessages().message.no,
    MAYBE: Utils.getLocalizedMessages().message.indifferent,
};

export const SpokenLanguageLevel = {
    NOTIONS: Utils.getLocalizedMessages().message.notions,
    INTERMEDIATE: Utils.getLocalizedMessages().message.intermediate,
    BILINGUAL: Utils.getLocalizedMessages().message.bilingual,
    NATIVE: Utils.getLocalizedMessages().message.native,
};

export const Role = {
    SALON: Utils.getLocalizedMessages().message.salon,
    ADMIN: Utils.getLocalizedMessages().message.admin,
};

export const SkillType = {
    technical: Utils.getLocalizedMessages().message.technical,
    relational: Utils.getLocalizedMessages().message.relational,
    personal: Utils.getLocalizedMessages().message.personal,
};

export interface Skill {
    type?: typeof SkillType;
    name?: string;
}

export interface Experience {
    startDate?: number;
    endDate?: number;
    currentPost?: boolean;
    salonName?: string;
    jobTitle?: string;
    jobDescription?: string;
}

export interface SpokenLanguages {
    name?: string;
    level?: string;
}

export interface User {
    id: string;
    enabled?: boolean;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    phone?: string;
    profilePicture?: string;
    birthDate?: number;
    isManager: boolean;
    address?: Address;
    roles?: Array<typeof Role>;
    createdAt?: number;
}

export interface Manager extends User {
    socialReason?: string;
    boughtResumes?: Array<string>;
    isSubscribed?: boolean;
}

export interface ManagerCreateRequest extends Manager {
    acceptCgu: boolean;
}

export interface Hairdresser extends User {
    spokenLanguages: Array<SpokenLanguages>;
    isGeographicallyAvailable?: typeof GeographicAvailability;
    brandsWorkedWith: Array<string>;
    portfolioPictures: Array<string>;
    formations: Array<string>;
    experiences: Array<Experience>;
    savedJobs: Array<string>;
    skills: Array<Skill>;
    contractType: Array<typeof Contract>;
    level?: Array<typeof HairdresserLevel>;
    video?: string;
}

export interface UserFilters {
    zipCode?: string;
    city?: string;
    firstName?: string;
    lastName?: string;
    sort?: string;
    email?: string;
    phone?: string;
}

export interface Resume {
    firstName: string;
    id: string;
    zipCode: string;
    formations: Array<string>;
    level: Array<HairdresserlevelStrings>;
}

export interface EmployeesFilters {
    zipCode?: string;
    level: Array<string>;
    id?: string;
    formations: Array<string>;
    sort?: string;
}

export interface SearchEmployeesResponse {
    employeesFound: number;
}

/**
 * Create a default hairdresser
 *
 * @returns a default hairdresser
 */
export function defaultHairdresser(testManager: boolean): Hairdresser {
    return {
        id: '',
        enabled: !testManager,
        contractType: [],
        isManager: false,
        brandsWorkedWith: [],
        experiences: [],
        formations: [],
        password: '',
        portfolioPictures: [],
        savedJobs: [],
        skills: [],
        spokenLanguages: [],
        roles: [],
        createdAt: 0,
    };
}

/**
 * Create a default manager
 * @param testManager true if test user creation false otherwise
 * @returns a default manager
 */
export function defaultManager(testManager: boolean): Manager {
    return {
        enabled: !testManager,
        id: '',
        isManager: true,
        password: '',
        roles: [],
        createdAt: 0,
        boughtResumes: [],
        isSubscribed: false,
    };
}
