import { axiosAPI } from '@/axiosInstance';
import { SearchFilter } from '@/model/generic';
import Utils from '@/utils/utils';
import { AxiosError, AxiosResponse } from 'axios';
import { NAlert } from 'naive-ui';
import { MessageApiInjection } from 'naive-ui/es/message/src/MessageProvider';
import { RenderMessageProps } from 'naive-ui/es/message/src/types';
import { h } from 'vue';

export interface ErrorMessage {
    title: string;
    content: string;
}

const defaultErrorMessages = new Map<number, ErrorMessage>([
    [
        400,
        {
            title: 'Requête incorrecte',
            content: 'Il y a une erreur dans votre requête, vérifiez votre demande et réessayez.',
        },
    ],
    [
        401,
        {
            title: 'Session invalide',
            content: 'Votre session est invalide, ou a expiré, veuillez vous réauthentifier.',
        },
    ],
    [
        402,
        {
            title: 'Paiement requis',
            content:
                'Désolé, votre demande ne peut être traitée car le paiement est nécessaire pour accéder à ce contenu ou à ce service.',
        },
    ],
    [
        403,
        {
            title: 'Accès interdit',
            content:
                "Vos autorisations sont insuffisantes pour accéder à la ressource demandée. Contactez un administrateur si vous pensez que c'est une erreur.",
        },
    ],
    [
        404,
        {
            title: 'Ressource introuvable',
            content:
                "La ressource que vous tentez de consulter n'existe pas sur le serveur, veuillez vérifier votre saisie.",
        },
    ],
    [
        500,
        {
            title: 'Erreur serveur',
            content:
                'Une erreur interne est survenue dans le traitement de votre demande, veuillez réessayer plus tard.',
        },
    ],
]);

export const apiSettings = {
    endpoints: {
        users: '/users',
        managers: '/users/managers',
        hairdressers: '/users/hairdressers',
        salons: '/salons',
        jobs: '/jobs',
        suggestions: '/suggestions',
        applications: '/applications',
        products: '/products',
        orders: '/orders',
        invoices: '/invoices',
        resumes: '/resumes',
        stock: '/stock',
        config: '/config',
    },
};

/**
 * Check if response is http 200, if no throw response status
 * @param response response
 * @param expectedStatus the expected status
 * @param message the message api
 * @param customErrorMessage the custom error messages
 */
export async function checkResponseStatus(
    response: AxiosResponse,
    expectedStatus: number,
    message?: MessageApiInjection,
    customErrorMessage: Map<number, ErrorMessage> = new Map<number, ErrorMessage>()
    // Disabled cause do not really know response type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    if (response.status != expectedStatus) {
        if (message) {
            const errorMessage: ErrorMessage | undefined = customErrorMessage.get(
                response.status
            ) ??
                defaultErrorMessages.get(response.status) ?? {
                    title: 'Erreur',
                    content: 'Une erreur inconnue est survenue, veuillez réessayer plus tard',
                };
            message.destroyAll();
            message.error(
                errorMessage?.content ??
                    'Une erreur inattendue est survenue, veuillez réessayer plus tard.',
                {
                    duration: 5000,
                    render(props: RenderMessageProps) {
                        return h(
                            NAlert,
                            {
                                type: 'error',
                                title: errorMessage?.title ?? 'Erreur',
                                style: {
                                    boxShadow: 'var(--n-box-shadow)',
                                    maxWidth: 'calc(100vw - 32px)',
                                    width: '480px',
                                },
                            },
                            {
                                default: () => props.content,
                            }
                        );
                    },
                }
            );
            throw response.status;
        }
    }
    if (expectedStatus != 204) {
        return response.data;
    }
    return;
}

/**
 * Check if response is http 200, if no throw response status
 * @param error
 * @param expectedStatus the expected status
 * @param message the message api
 * @param customErrorMessage the custom error messages
 */
export async function checkErrorStatus(
    error: AxiosError,
    message: MessageApiInjection,
    customErrorMessage: Map<number, ErrorMessage> = new Map<number, ErrorMessage>()
    // Disabled cause do not really know response type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    if (error.code === AxiosError.ERR_NETWORK) {
        createErrorMessage(message, 'Une erreur réseau est survenue, veuillez réessayer plus tard');
        throw error;
    }

    const codeStatus: number = error.response?.status ?? (error.status as number);

    const errorMessage: ErrorMessage = customErrorMessage.get(codeStatus) ??
        defaultErrorMessages.get(codeStatus) ?? {
            title: 'Erreur',
            content: 'Une erreur inconnue est survenue, veuillez réessayer plus tard',
        };
    message.destroyAll();
    createErrorMessage(
        message,
        errorMessage.content ?? 'Une erreur inattendue est survenue, veuillez réessayer plus tard.',
        errorMessage.title
    );

    throw codeStatus;
}

/**
 * Download a csv file with the match users
 * @param filters the applied filters
 * @param endpoint the export endpoint
 * @param messageApi the message api
 */
export async function exportToCsv(
    filters: SearchFilter,
    endpoint: string,
    messageApi: MessageApiInjection
): Promise<void> {
    messageApi.loading('Nous générons votre export, veuillez patienter');
    try {
        const url = `${endpoint}/export?${Utils.objectToQueryParameters(filters, true)}`;
        const res: AxiosResponse = await axiosAPI.get(url, { responseType: 'blob' });
        messageApi.destroyAll();
        if (res.status === 200) {
            messageApi.success('Export généré avec succès');
            Utils.downloadFile(res.data, 'zestee-export.csv');
        } else if (res.status === 204) {
            messageApi.info(
                "Nous ne trouvons pas d'informations à exporter, vérifiez vos filtres et essayez de nouveau"
            );
        }
    } catch (error) {
        return checkErrorStatus(error as AxiosError, messageApi);
    }
}

export function createErrorMessage(message: MessageApiInjection, content: string, title?: string) {
    message.error(content, {
        duration: 5000,
        render(props: RenderMessageProps) {
            return h(
                NAlert,
                {
                    type: 'error',
                    title: title ?? 'Erreur',
                    style: {
                        boxShadow: 'var(--n-box-shadow)',
                        maxWidth: 'calc(100vw - 32px)',
                        width: '480px',
                    },
                },
                {
                    default: () => props.content,
                }
            );
        },
    });
}
