import axios, { AxiosError } from 'axios';
import keycloakService, { saveInformations } from '@/security/keycloak-service';
import { userStore } from '@/store/user';
import userApi from '@/api/users';

export const axiosAPI = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json,text/csv,application/pdf,image/jpeg',
    },
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    withXSRFToken: true,
});

export const axiosKeycloak = axios.create({
    baseURL: `${process.env.VUE_APP_KEYCLOAK_URL}realms/${process.env.VUE_APP_KEYCLOAK_REALM}`,
});

axiosAPI.interceptors.request.use((config) => {
    const token = localStorage.getItem('zestee-token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

let retry = false;

axiosAPI.interceptors.response.use(
    (response) => {
        const xsrfToken = response.headers['x-xsrf-token'];
        if (xsrfToken) {
            axiosAPI.defaults.headers['X-XSRF-TOKEN'] = xsrfToken;
        }
        retry = false;
        return response;
    },
    (error: AxiosError) => {
        if (!error.response) {
            if (userStore.state.isAuthenticated) keycloakService.logout(true, true);
            return Promise.reject(error);
        }
        if (error.response.status === 401 && !retry) {
            retry = true;
            try {
                keycloakService.refreshToken(error.config!.url !== '/users/xsrf');
            } catch (e: any) {
                return Promise.reject(error.response);
            }
        }
        return Promise.reject(error.response);
    }
);

export const checkAPI = async () => {
    try {
        await userApi.getUserInfo();
        if (!userStore.state.isAuthenticated) await saveInformations();
        userStore.state.isAuthenticated = true;
    } catch (e: any) {
        return;
    }
    return;
};
