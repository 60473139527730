import { Entity, SortableFilterState } from '@/model/generic';
import { DataTableBaseColumn, DataTableSortState } from 'naive-ui';
import { SortOrder, TableColumn } from 'naive-ui/es/data-table/src/interface';
import { LocationQueryRaw, Router } from 'vue-router';

/**
 * Handle the sort event to put the state in the table and refresh with sorted data.
 * This only manage one column for the moment.
 * @param columns columns
 * @param sorter sorter state
 */
function toQueryParameters(columns: Array<TableColumn<Entity>>, sorter: DataTableSortState) {
    for (const column of columns) {
        const col = column as DataTableBaseColumn<Entity>;
        if (col.key === sorter.columnKey) {
            col.sortOrder = sorter.order;
            if (sorter.order === false) {
                return undefined;
            }
            return `${col.key},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
        }
    }
}

/**
 * Generate the string for sortOrder parameter from the field key and the filter string
 * @param fieldKey the field key to check
 * @param filter the filter string
 * @returns the sortOrder string
 */
function getFieldSort(fieldKey: string, filter: string | undefined): SortOrder {
    if (filter === undefined || !filter.includes(fieldKey)) {
        return false;
    }
    return filter.includes('asc') ? 'ascend' : 'descend';
}

/**
 * Zip code sorter
 * @param zipCode1 first zipcode
 * @param zipCode2 second zipcode
 * @returns 1 if zipcode1 is before zipcode2, -1 otherwise
 */
function zipCodeSorter(zipCode1: string | undefined, zipCode2: string | undefined): number {
    const zipCode1AsNumber = Number.parseInt(zipCode1 ?? '0');
    const zipCode2AsNumber = Number.parseInt(zipCode2 ?? '0');
    return zipCode1AsNumber - zipCode2AsNumber;
}

/**
 * Handle the sort event to put the state in the table and refresh with sorted data
 * @param sorter the sorter state
 */
function handleSort(
    sorter: DataTableSortState,
    filters: SortableFilterState,
    columns: Array<TableColumn<Entity>>,
    router: Router
): void {
    filters.sort = SortUtils.toQueryParameters(columns, sorter);
    router.replace({
        query: filters as LocationQueryRaw,
    });
}

const SortUtils = {
    toQueryParameters,
    getFieldSort,
    zipCodeSorter,
    handleSort,
};

export default SortUtils;
